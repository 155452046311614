/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { FC } from "react";
import clsx from "clsx";
import { observer } from "mobx-react";
import { Link, useLocation } from "react-router-dom";
import Joyride from "react-joyride";

import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import stores from "../../../app/stores";

const adminTabs: ReadonlyArray<{
  link: string;
  icon: string;
  tooltip: string;
}> = [
  {
    link: "chat",
    icon: "media/icons/duotune/communication/com007.svg",
    tooltip: "Chat",
  },
  {
    link: "connections",
    icon: "media/icons/duotune/general/gen008.svg",
    tooltip: "Connections",
  },
  {
    link: "models",
    icon: "media/icons/duotune/technology/teh003.svg",
    tooltip: "Models",
  },
  {
    link: "avatars",
    icon: "media/icons/duotune/technology/teh002.svg",
    tooltip: "Avatars",
  },
  {
    link: "storages",
    icon: "media/icons/duotune/graphs/gra010.svg",
    tooltip: "Storages",
  },
  {
    link: "company-users",
    icon: "media/icons/duotune/communication/com014.svg",
    tooltip: "Users",
  },
];

const memberTabs: ReadonlyArray<{
  link: string;
  icon: string;
  tooltip: string;
}> = [
  // {
  //   link: "dashboard",
  //   icon: "/media/icons/duotune/general/gen001.svg",
  //   tooltip: "Dashboard",
  // },
  // {
  //   link: "chat",
  //   icon: "/media/icons/duotune/communication/com007.svg",
  //   tooltip: "Chat",
  // },
  // {
  //   link: "avatars",
  //   icon: "media/icons/duotune/technology/teh002.svg",
  //   tooltip: "Avatars",
  // },
];

type Props = {
  link: string;
  setLink: (link: string) => void;
};

const AsideTabs: FC<Props> = observer(({ link, setLink }) => {
  const location = useLocation();
  const currentLink = location.pathname.slice(1);

  const steps = [
    {
      target: ".models",
      title: "Connect Models",
      content:
        "First, you need to connect at least one of the models listed on this page by entering the required information.",
    },
    {
      target: ".connections",
      title: "Connect Apps",
      content:
        "From this section, you need to connect at least one of the applications listed in our app store. You can track the process through toast messages, and we will send you a notification email once the process is complete.",
    },
    {
      target: ".avatars",
      title: "Create Avatars",
      content:
        "From this section, you can create AI Avatar Managers for the departments in your company. The Avatar creation wizard automatically selects the applications related to the chosen department. There is a section within the wizard where you can change these applications, allowing you to create the desired AI Avatar Manager as you wish.",
    },
    {
      target: ".company-users",
      title: "Invite Users",
      content:
        "From this section, you can invite your employees. When the invited users join the system, you will need to select a department for them. Employees will only have access to the AI Avatar Managers and applications related to their designated departments.",
    },
    {
      target: ".chat",
      title: "Chat",
      content: "You can start using the system by creating a new chat.",
    },
  ];

  return (
    <div
      className="hover-scroll-y mb-10"
      data-kt-scroll="true"
      data-kt-scroll-activate="{default: false, lg: true}"
      data-kt-scroll-height="auto"
      data-kt-scroll-wrappers="#kt_aside_nav"
      data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
      data-kt-scroll-offset="0px"
    >
      {!stores.userStore.currentUser.settings.is_tour_part_1_completed &&
      stores.userStore.isCurrentUserAdmin ? (
        <Joyride
          steps={steps}
          showProgress={true}
          continuous={true}
          run={true}
          styles={{
            tooltipTitle: {
              fontSize: 15,
              color: "var(--bs-text-gray-900)",
              fontWeight: 700,
            },
            tooltipContent: {
              fontSize: 13,
              textAlign: "left",
              color: "var(--bs-text-gray-900)",
            },
            buttonNext: {
              backgroundColor: " var(--bs-primary)",
              color: "var(--bs-primary-inverse)",
              fontSize: 13,
              fontWeight: 400,
            },
            buttonBack: {
              color: "var(--bs-light-inverse)",
              fontSize: 13,
              fontWeight: 400,
            },
          }}
          callback={(e) => {
            if (
              e.status === "finished" &&
              !stores.userStore.currentUser.settings.is_tour_part_1_completed
            ) {
              stores.userStore.currentUser.settings.is_tour_part_1_completed =
                true;
              stores.userStore.updateUserData();
            }
          }}
        />
      ) : null}

      {/* begin::Nav */}
      <ul className="nav flex-column" id="kt_aside_nav_tabs">
        {/* begin::Nav item */}
        <div
          className="aside-logo d-none d-lg-flex flex-column align-items-center flex-column-auto py-10"
          id="kt_aside_logo"
        >
          <Link to="/">
            <img
              src={toAbsoluteUrl(
                "https://imagedelivery.net/prp7br5UdCsJqvWC5hRwlA/b4d98b10-c21b-4560-d177-28f002687c00/mid"
              )}
              alt="logo"
              className="theme-dark-show h-40px"
            />
            <img
              src={toAbsoluteUrl(
                "https://imagedelivery.net/01kSRcVEVAYmOHOBQNSt0A/6c78f836-e585-45cf-0ddd-85f496547500/mid"
              )}
              alt="logo"
              className="theme-light-show h-40px"
            />
          </Link>
        </div>

        {stores.userStore.isCurrentUserAdmin
          ? adminTabs.map((t) => (
              <li className={t.link} key={t.link}>
                {/* begin::Nav link */}
                <a
                  className={clsx(
                    "mt-4 nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light",
                    { active: t.link === currentLink }
                  )}
                  onClick={() => setLink(t.link)}
                >
                  <KTSVG path={t.icon} className="svg-icon svg-icon-2x" />
                </a>
                {/* end::Nav link */}
              </li>
            ))
          : memberTabs.map((t) => (
              <li key={t.link}>
                {/* begin::Nav link */}
                <a
                  className={clsx(
                    "mt-4 nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light",
                    { active: t.link === currentLink }
                  )}
                  onClick={() => setLink(t.link)}
                >
                  <KTSVG path={t.icon} className="svg-icon svg-icon-2x" />
                </a>
                {/* end::Nav link */}
              </li>
            ))}
        {}
        {/* end::Nav link */}
      </ul>
      {/* end::Tabs */}
    </div>
  );
});

export { AsideTabs };
