import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { CompanyAvatarApp } from "../../app/models/CompanyAvatarApp";

export default async function getCompanyAvatarApps(
  companyAvatarId: number
): Promise<CompanyAvatarApp[]> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const query = gql`
      query CompanyAvatarAppsQuery($company_avatar_id: ID!) {
        companyAvatarApps(company_avatar_id: $company_avatar_id) {
          id
          company_avatar_id
          company_app_id
        }
      }
    `;

    client
      .query({
        query: query,
        variables: {
          company_avatar_id: String(companyAvatarId),
        },
        fetchPolicy: "no-cache",
      })
      .then((queryResult: any) => {
        const companyAvatarApps: CompanyAvatarApp[] = get(
          queryResult,
          "data.companyAvatarApps",
          []
        );

        resolve(companyAvatarApps);
      })
      .catch((error: any) => {
        console.error("Error fetching company avatar apps:", error);
        reject(error);
      });
  });
}
