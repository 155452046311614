/* @flow */

import stores from "../../app/stores";

export type CreateAvatarIndexDataResult = {
  success: boolean;
  message: string;
};

export default async function createAvatarIndex(
  companyId: number,
  companyAvatarId: number,
  companyStorageId: number
): Promise<CreateAvatarIndexDataResult> {
  return new Promise(async (resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_URL}/createAvatarIndex`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "client-id": stores.userStore.socketClientId,
      },
      body: JSON.stringify({
        companyId: companyId,
        companyAvatarId: companyAvatarId,
        companyStorageId: companyStorageId,
      }),
      credentials:
        process.env.REACT_APP_IS_DEV === "false" ? "include" : "same-origin",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          const result: CreateAvatarIndexDataResult = data;

          resolve(result);
        } else {
          reject();
        }
      });
  });
}
