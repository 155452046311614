import { gql } from "@apollo/client";
import ApolloClientHelper from "../ApolloClientHelper";
import type { CompanyAvatarApp } from "../../app/models/CompanyAvatarApp";
import { get } from "lodash";

async function createCompanyAvatarApp(
  companyAvatarId: number,
  companyAppId: number
): Promise<CompanyAvatarApp> {
  const client = ApolloClientHelper.getApolloClient();

  const mutation = gql`
    mutation CreateCompanyAvatarApp(
      $company_avatar_id: Int!
      $company_app_id: Int!
    ) {
      createCompanyAvatarApp(
        company_avatar_id: $company_avatar_id
        company_app_id: $company_app_id
      ) {
        id
        company_avatar_id
        company_app_id
      }
    }
  `;

  try {
    const { data } = await client.mutate({
      mutation,
      variables: {
        company_avatar_id: companyAvatarId,
        company_app_id: companyAppId,
      },
    });

    const companyAvatarApp = get(data, "createCompanyAvatarApp", null);

    if (companyAvatarApp) {
      return companyAvatarApp;
    } else {
      throw new Error("Failed to create CompanyAvatarApp.");
    }
  } catch (error) {
    console.error("Error creating CompanyAvatarApp:", error);
    throw error;
  }
}

export default createCompanyAvatarApp;
