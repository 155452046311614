/* @flow */

import { Subscription } from "../../app/models/Subscription";
import stores from "../../app/stores";

export default async function getSubscriptions(
  customerId: string
): Promise<Subscription[]> {
  return new Promise(async (resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_URL}/customer-subscriptions`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ customerId: customerId }),
      credentials:
        process.env.REACT_APP_IS_DEV === "false" ? "include" : "same-origin",
    }).then(async (result) => {
      try {
        if (result.status === 401) {
          stores.userStore.logout();
          resolve([]);
        } else {
          const data = await result.json();

          let subscriptionItems: Subscription[] = data;

          resolve(subscriptionItems);
        }
      } catch (error) {
        resolve([]);
      }
    });
  });
}
