import { action, observable, when } from "mobx";

import {
  getCompanyAvatars,
  createCompanyAvatar,
  createAvatarIndex,
  createGeneralIndex,
  deleteAvatarIndex,
  updateCompanyAvatar,
  updateCompanyAvatarDepartment,
  getCompanyUserDepartments,
  getCompanyAvatarApps,
} from "../../helpers/api";
import { CompanyAvatar } from "../models/CompanyAvatar";
import {
  FeatureType,
  CompanyAvatarStatus,
  UserActionLogStatus,
} from "../../helpers/Enums";
import SocketHelper from "../../helpers/SocketHelper";

import RootStore from "./RootStore";
import stores from ".";
import { toast } from "react-toastify";
import i18n from "../../i18n";
import createCompanyAvatarDepartment from "../../helpers/api/createCompanyAvatarDepartment";
import createCompanyAvatarApp from "../../helpers/api/createCompanyAvatarApp";
import deleteCompanyAvatarApp from "../../helpers/api/deleteCompanyAvatarApp";

export default class CompanyAvatarStore {
  @observable companyAvatars: CompanyAvatar[] = [];
  @observable selectedCompanyAvatar: CompanyAvatar | undefined = undefined;
  @observable isLoading: boolean = false;
  @observable isAvatarLoading: boolean = false;

  constructor(rootStore: RootStore) {
    when(
      () =>
        rootStore.companyStore.selectedUserCompany !== undefined &&
        rootStore.companyUserStore.isLoading === false,
      () => {
        this.getCompanyAvatars();

        SocketHelper.addMessageHandler(this.handleMessage);
      }
    );
  }

  private handleMessage = async (data: any) => {
    if (data.companyAvatarId) {
      const companyAvatar = this.companyAvatars.find(
        (item) => item.id === data.companyAvatarId
      );

      if (data.processId === 6) {
        if (companyAvatar && !data.isIndexCreating && data.isIndexCreated) {
          companyAvatar.last_sync_at = Date.now();

          await stores.userActionLogStore.createUserActionLog(
            `${companyAvatar.name} data index created.`,
            "",
            0,
            0,
            UserActionLogStatus.Success
          );

          toast.update(companyAvatar.id, {
            render: `${companyAvatar.name} data index created.`,
            type: "success",
            isLoading: false,
            autoClose: 3000,
            closeButton: true,
            position: "top-center",
          });

          this.getCompanyAvatars();
        } else if (
          companyAvatar &&
          !data.isIndexCreating &&
          !data.isIndexCreated
        ) {
          await stores.userActionLogStore.createUserActionLog(
            `${companyAvatar.name} index creation error: ${data.message}`,
            "",
            0,
            0,
            UserActionLogStatus.Declined
          );

          companyAvatar.last_sync_at = 0;
          companyAvatar.status = CompanyAvatarStatus.Failed;

          toast.update(companyAvatar.id, {
            render: `${companyAvatar.name} index creation error`,
            type: "error",
            isLoading: false,
            autoClose: 3000,
            closeButton: true,
            position: "top-center",
          });

          this.getCompanyAvatars();
        }
      }
    }
  };

  @action checkGeneralIndex = async () => {
    if (
      stores.companyStore.selectedUserCompany &&
      !stores.companyStore.selectedUserCompany.is_index_created &&
      stores.companyStorageStore.selectedCompanyStorage
    ) {
      const result = await createGeneralIndex(
        stores.companyStore.selectedUserCompany.id,
        stores.companyStorageStore.selectedCompanyStorage.id
      );

      if (result.success) {
        stores.companyStore.selectedUserCompany.is_index_created = true;
        stores.companyStore.updateCompany(
          stores.companyStore.selectedUserCompany
        );
      }
    }
  };

  @action updateCompanyAvatar = async (
    companyAvatar: Partial<CompanyAvatar>,
    departmentId: number | null,
    selectedAppIds: number[]
  ): Promise<boolean> => {
    this.isLoading = true;
    try {
      const result = await updateCompanyAvatar(companyAvatar);
      if (result) {
        const index = this.companyAvatars.findIndex(
          (avatar) => Number(avatar.id) === Number(companyAvatar.id)
        );

        if (index !== -1) {
          this.companyAvatars[index] = {
            ...this.companyAvatars[index],
            ...companyAvatar,
          };
        }

        if (departmentId !== null) {
          await updateCompanyAvatarDepartment(
            Number(companyAvatar.id),
            departmentId
          );
        }

        const companyAvatarApp = await getCompanyAvatarApps(
          Number(companyAvatar.id)
        );

        const appsToDelete = companyAvatarApp.filter(
          (app) =>
            !selectedAppIds.toString().includes(app.company_app_id.toString())
        );

        const appsToCreate = selectedAppIds.filter(
          (appId) =>
            !companyAvatarApp.some(
              (app) => app.company_app_id.toString() === appId.toString()
            )
        );

        await Promise.all(
          appsToCreate.map((appId) =>
            createCompanyAvatarApp(Number(companyAvatar.id), Number(appId))
          )
        );

        await Promise.all(
          appsToDelete.map((app) => deleteCompanyAvatarApp(Number(app.id)))
        );

        if (companyAvatar.id) {
          await this.createAvatarIndex(this.companyAvatars[index]);
        }

        return true;
      }
      return false;
    } catch (error) {
      console.error("Failed to update company avatar:", error);
      return false;
    } finally {
      this.isLoading = false;
    }
  };

  @action getCompanyAvatars = async () => {
    this.isLoading = true;

    try {
      const company_id = stores.companyStore.selectedUserCompany?.id;
      if (company_id) {
        const avatars = await getCompanyAvatars(company_id);

        if (stores.userStore.isCurrentUserAdmin) {
          this.companyAvatars = avatars;
        } else {
          const currentCompanyUser =
            stores.companyUserStore.nonFilteredCompanyUsers.find(
              (companyUser) =>
                companyUser.user_id.toString() ===
                stores.userStore.currentUser.id.toString()
            );

          if (currentCompanyUser) {
            const companyUserDepartments = await getCompanyUserDepartments(
              currentCompanyUser.id
            );

            const currentUserAvatars: CompanyAvatar[] = [];

            companyUserDepartments.forEach((companyUserDepartment) => {
              avatars.forEach((avatar) => {
                if (
                  avatar.company_avatar_departments.findIndex(
                    (company_avatar_department) =>
                      company_avatar_department.department_id.toString() ===
                      companyUserDepartment.department_id.toString()
                  ) !== -1
                )
                  currentUserAvatars.push(avatar);
              });
            });

            if (currentUserAvatars.length > 0) {
              this.selectedCompanyAvatar = currentUserAvatars[0];
            }

            this.companyAvatars = currentUserAvatars;
          }
        }
      }
    } catch (error) {
      console.error("Failed to fetch company avatars:", error);
    } finally {
      this.isLoading = false;
    }
  };

  @action getAvatarDepartmentId = (avatarId: number): number | null => {
    const avatar = this.companyAvatars.find((avatar) => avatar.id === avatarId);
    if (
      avatar &&
      avatar.company_avatar_departments &&
      avatar.company_avatar_departments.length > 0
    ) {
      return avatar.company_avatar_departments[0].department_id;
    }
    return null;
  };

  @action createCompanyAvatar(
    company_id: number,
    name: string,
    description: string,
    logo_url: string,
    departmentId: number,
    selectedAppIds: number[]
  ): Promise<CompanyAvatar> {
    return new Promise(async (resolve, reject) => {
      if (
        !(await stores.userStore.checkSubscribedFeatureType(
          FeatureType.CreateAvatar
        ))
      ) {
        reject();
        return;
      }

      this.isLoading = true;
      try {
        const newAvatar = await createCompanyAvatar(
          company_id,
          name,
          description,
          logo_url,
          CompanyAvatarStatus.None,
          0
        );

        this.companyAvatars.push(newAvatar);

        await createCompanyAvatarDepartment(Number(newAvatar.id), departmentId);

        const numericAppIds = selectedAppIds.map((id) => Number(id));

        await Promise.all(
          numericAppIds.map((appId) =>
            createCompanyAvatarApp(Number(newAvatar.id), appId)
          )
        );
        await stores.companyAvatarStore.getCompanyAvatars();

        this.createAvatarIndex(newAvatar);

        this.isLoading = false;

        resolve(newAvatar);
      } catch (error) {
        console.error(error);

        this.isLoading = false;

        reject(error);
      }
    });
  }

  @action createAvatarIndex(companyAvatar: CompanyAvatar): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      if (!stores.companyStorageStore.selectedCompanyStorage) {
        toast.error(i18n.ToastMessages.nonStorageError, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeButton: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        return;
      }

      const actionToastid = toast.loading(
        `${companyAvatar.name} index creating`,
        {
          position: "top-center",
          autoClose: 5000,
          toastId: companyAvatar.id,
          closeButton: true,
        }
      );

      const userActionLog = await stores.userActionLogStore.createUserActionLog(
        `${companyAvatar.name} index creating`,
        "",
        0,
        0,
        UserActionLogStatus.InProgress
      );

      const createAvatarIndexDataResult = await createAvatarIndex(
        companyAvatar.company_id,
        companyAvatar.id,
        stores.companyStorageStore.selectedCompanyStorage.id
      );

      if (createAvatarIndexDataResult.success) {
        userActionLog.action_status = UserActionLogStatus.Success;
        userActionLog.action_result = `${companyAvatar.name} index create started`;

        stores.userActionLogStore.updateUserActionLog(userActionLog);

        resolve(true);
      } else {
        userActionLog.action_status = UserActionLogStatus.Declined;
        userActionLog.action_result = `${companyAvatar.name} index creation error: ${createAvatarIndexDataResult.message}`;

        stores.userActionLogStore.updateUserActionLog(userActionLog);

        toast.update(actionToastid, {
          render: `${companyAvatar.name} index creation error`,
          type: "error",
          isLoading: false,
          autoClose: 3000,
          closeButton: true,
          position: "top-center",
        });

        resolve(false);
      }
    });
  }

  @action deleteCompanyAvatar = async (avatarId: number): Promise<boolean> => {
    this.isLoading = true;
    try {
      if (
        stores.companyStore.selectedUserCompany &&
        stores.companyStorageStore.selectedCompanyStorage
      ) {
        await deleteAvatarIndex(
          stores.companyStore.selectedUserCompany.id,
          avatarId,
          stores.companyStorageStore.selectedCompanyStorage.id
        );

        this.companyAvatars = this.companyAvatars.filter(
          (c) => c.id !== avatarId
        );
      }

      return true;
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  };
}
