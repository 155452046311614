/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { observer } from "mobx-react";
import MarkdownPreview from "@uiw/react-markdown-preview";
import clsx from "clsx";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  PinterestShareButton,
  RedditShareButton,
  ViberShareButton,
  LineShareButton,
} from "react-share";

import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import stores from "../../stores";
import Functions from "../../../helpers/Functions";
import { DocumentMessage } from "../../models/DocumentMessage";
import { App } from "../../models/App";
import { updateDocumentMessage } from "../../../helpers/api";

// import { SourceDocumentCard } from "./SourceDocumentCard";

interface State {
  isLiked?: boolean;
  isCopied: boolean;
  isModalOpen: boolean;
  isHovered: boolean;
}
interface Props {
  index: number;
  documentMessage: DocumentMessage;
  chatUpdateFlag: boolean;
  onReplyLike: () => void;
  onReplyDislike: () => void;
  onSpeechToAvatar: (message: string) => void;
  onRewriteMessage: (documentMessage: DocumentMessage) => void;
  canUseLiveAvatar: boolean;
  refreshTime: number;
}

@observer
export default class MessageItem extends React.Component<Props, State> {
  state: State = {
    isLiked:
      this.props.documentMessage.is_liked === true
        ? true
        : this.props.documentMessage.is_liked === false
        ? false
        : undefined,
    isCopied: false,
    isModalOpen: false,
    isHovered: false,
  };

  private lastDecodedMessage: string = "";
  private lastDecodedMessageReply: string = "";

  handleLike = () => {
    this.setState(
      (prevState) => ({
        isLiked: prevState.isLiked === true ? undefined : true,
      }),
      () => {
        updateDocumentMessage({
          ...this.props.documentMessage,
          is_liked: this.state.isLiked,
        });
      }
    );
  };

  handleDislike = () => {
    this.setState(
      (prevState) => ({
        isLiked: prevState.isLiked === false ? undefined : false,
      }),
      () => {
        updateDocumentMessage({
          ...this.props.documentMessage,
          is_liked: this.state.isLiked,
        });
      }
    );
  };

  handleCopy = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        this.setState({ isCopied: true }, () => {
          setTimeout(() => {
            this.setState({ isCopied: false });
          }, 2000);
        });
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  toggleModal = () => {
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }));
  };

  handleMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ isHovered: false });
  };

  private renderMessageReplyItem = (
    isAvatarEnabled: boolean,
    documentMessage: DocumentMessage,
    index: number
  ) => {
    const templateAttr = {};

    const contentClass = `d-flex justify-content-start mb-10`;

    const app: App | undefined = !documentMessage.company_app
      ? undefined
      : stores.companyAppStore.apps.find(
          (app) =>
            documentMessage.company_app &&
            app.id.toString() === documentMessage.company_app.app_id.toString()
        );

    const name = app
      ? app.name
      : documentMessage.company_avatar
      ? documentMessage.company_avatar.name
      : "The Chief";

    const logo = app
      ? app.logo
      : documentMessage.company_avatar
      ? documentMessage.company_avatar.logo_url
      : "https://imagedelivery.net/01kSRcVEVAYmOHOBQNSt0A/93bbf41d-9c33-44c7-b6eb-38fe5b9a1300/mid";

    let decodedMessageReply = this.lastDecodedMessageReply;

    try {
      decodedMessageReply = Functions.decodeMessage(
        documentMessage.message_reply
      );
      this.lastDecodedMessageReply = decodedMessageReply;
    } catch (error) {
      console.log(error);
    }

    return (
      <div
        key={`message_reply${index}`}
        className={clsx("d-flex", contentClass, "mb-10")}
        {...templateAttr}
      >
        <div
          className={clsx(
            "d-flex flex-column align-items",
            `align-items-start`
          )}
          style={{ width: "100%" }}
        >
          <div
            className="d-flex align-items-center mb-2"
            style={{ width: "100%" }}
          >
            <div className="symbol  symbol-50px mb-2 me-3">
              <img alt="Pic" src={toAbsoluteUrl(logo)} />
            </div>

            <div>
              <a
                href="#"
                className="fs-5 fw-bolder text-gray-900 text-hover-primary me-1"
              >
                {name}
              </a>

              {/* <span className="text-muted fs-7 mb-1">
                        {message.time}
                      </span> */}
            </div>
          </div>

          {documentMessage.message_reply !== "" ? (
            <div style={{ width: "auto", maxWidth: "100%" }}>
              <MarkdownPreview
                source={decodedMessageReply}
                style={{ fontSize: 14 }}
                className={clsx(
                  "p-5 pb-10 rounded",
                  "text-dark fw-bold",
                  "text-start end",
                  "bg-light-info"
                )}
              />
              <div
                className="actions"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  paddingTop: 5,
                  paddingBottom: 5,
                  marginBottom: -15,
                }}
              >
                <button
                  onClick={this.toggleModal}
                  className="btn btn-icon w-auto me-2"
                  style={{ height: "auto", padding: "5px 10px" }}
                >
                  <i
                    className="fas fa-share p-0"
                    style={{
                      color: "grey",
                      fontSize: "1.2rem",
                    }}
                  ></i>
                </button>

                <button
                  onClick={() => this.handleCopy(decodedMessageReply)}
                  className="btn btn-icon w-auto me-2"
                  style={{ height: "auto", padding: "5px 10px" }}
                >
                  <i
                    className={`fas fa-${
                      this.state.isCopied ? "check" : "copy"
                    } p-0`} // Change icon when copied
                    style={{
                      color: this.state.isCopied ? "var(--bs-success)" : "grey",
                      fontSize: "1.2rem",
                    }}
                  ></i>
                </button>

                <button
                  onClick={this.handleLike}
                  className="btn btn-icon w-auto me-2"
                  style={{
                    height: "auto",
                    padding: "5px 10px",
                  }}
                >
                  <i
                    className="fas fa-thumbs-up p-0"
                    style={{
                      color: this.state.isLiked ? "var(--bs-primary)" : "grey",
                      fontSize: "1.2rem",
                    }}
                  ></i>
                </button>

                <button
                  onClick={this.handleDislike}
                  className="btn btn-icon w-auto me-2"
                  style={{ height: "auto", padding: "5px 10px" }}
                >
                  <i
                    className="fas fa-thumbs-down p-0"
                    style={{
                      color:
                        this.state.isLiked === false
                          ? "var(--bs-danger)"
                          : "grey",
                      fontSize: "1.2rem",
                    }}
                  ></i>
                </button>

                {isAvatarEnabled ? (
                  <button
                    onClick={() =>
                      this.props.onSpeechToAvatar(decodedMessageReply)
                    }
                    className="btn btn-icon w-auto ps-2 pb-2 pt-2"
                    style={{ height: "auto" }}
                  >
                    <KTSVG
                      path="media/icons/duotune/technology/teh002.svg"
                      className="svg-icon-1 me-2"
                    />
                  </button>
                ) : null}
              </div>
            </div>
          ) : (
            <div
              className={clsx(
                "p-5 rounded",
                "text-dark fw-bold",
                "text-start end",
                "bg-light-info"
              )}
            >
              <span
                className="spinner-border text-secondary ms-1"
                role="status"
              ></span>
            </div>
          )}

          {/* <div style={{ display: "flex", flexWrap: "wrap" }}>
            {documentMessage.source_documents &&
            stores.companyStore.selectedUserCompany
              ? Functions.decodeMessage(documentMessage.source_documents)
                  .split(",")
                  .slice(0, 5)
                  .map((doc) => {
                    const docInfo = Functions.extractFilenameAndExtension(doc);

                    if (doc.indexOf("blob") === -1) {
                      return (
                        <SourceDocumentCard
                          icon={`/media/svg/files/${docInfo.extension}.svg`}
                          title={`${
                            docInfo.filename
                          }.${docInfo.extension.toLowerCase()}`}
                          path={doc}
                          companyId={
                            stores.companyStore.selectedUserCompany?.id
                          }
                        />
                      );
                    } else return null;
                  })
              : null}
          </div> */}
        </div>

        {/* Share Modal */}
        <Modal show={this.state.isModalOpen} onHide={this.toggleModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>Share This Message</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex flex-wrap justify-content-around">
              <FacebookShareButton
                url={window.location.href}
                title={decodedMessageReply}
                className="me-2"
              >
                <i
                  className="fab fa-facebook p-0"
                  style={{ fontSize: "2rem", color: "#4267B2" }}
                ></i>
              </FacebookShareButton>
              <TwitterShareButton
                url={window.location.href}
                title={decodedMessageReply}
                className="me-2"
              >
                <i
                  className="fab fa-twitter p-0"
                  style={{ fontSize: "2rem", color: "#1DA1F2" }}
                ></i>
              </TwitterShareButton>
              <LinkedinShareButton
                url={window.location.href}
                title={decodedMessageReply}
                className="me-2"
              >
                <i
                  className="fab fa-linkedin p-0"
                  style={{ fontSize: "2rem", color: "#0077B5" }}
                ></i>
              </LinkedinShareButton>
              <WhatsappShareButton
                url={window.location.href}
                title={decodedMessageReply}
                className="me-2"
              >
                <i
                  className="fab fa-whatsapp p-0"
                  style={{ fontSize: "2rem", color: "#25D366" }}
                ></i>
              </WhatsappShareButton>
              <TelegramShareButton
                url={window.location.href}
                title={decodedMessageReply}
                className="me-2"
              >
                <i
                  className="fab fa-telegram p-0"
                  style={{ fontSize: "2rem", color: "#0088CC" }}
                ></i>
              </TelegramShareButton>
              <PinterestShareButton
                url={window.location.href}
                description={decodedMessageReply}
                media={logo}
                className="me-2"
              >
                <i
                  className="fab fa-pinterest p-0"
                  style={{ fontSize: "2rem", color: "#E60023" }}
                ></i>
              </PinterestShareButton>
              <RedditShareButton
                url={window.location.href}
                title={decodedMessageReply}
                className="me-2"
              >
                <i
                  className="fab fa-reddit p-0"
                  style={{ fontSize: "2rem", color: "#FF4500" }}
                ></i>
              </RedditShareButton>
              <ViberShareButton
                url={window.location.href}
                title={decodedMessageReply}
                className="me-2"
              >
                <i
                  className="fab fa-viber p-0"
                  style={{ fontSize: "2rem", color: "#7360F2" }}
                ></i>
              </ViberShareButton>
              <LineShareButton
                url={window.location.href}
                title={decodedMessageReply}
                className="me-2"
              >
                <i
                  className="fab fa-line p-0"
                  style={{ fontSize: "2rem", color: "#00C300" }}
                ></i>
              </LineShareButton>
              <EmailShareButton
                url={window.location.href}
                subject="Check out this message"
                body={decodedMessageReply}
                className="me-2"
              >
                <i
                  className="fas fa-envelope p-0"
                  style={{ fontSize: "2rem", color: "grey" }}
                ></i>
              </EmailShareButton>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.toggleModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  render() {
    const { documentMessage, index, canUseLiveAvatar } = this.props;

    const user = stores.userStore.currentUser;
    const state = "primary";

    const templateAttr = {};

    const contentClass = `d-flex justify-content-start mt-5`;

    let decodedMessage = this.lastDecodedMessage;

    try {
      decodedMessage = Functions.decodeMessage(documentMessage.message);
      this.lastDecodedMessage = decodedMessage;
    } catch (error) {
      console.log(error);
    }

    return (
      <>
        <div
          key={`message${index}`}
          className={clsx("d-flex", contentClass)}
          {...templateAttr}
        >
          <div
            className={clsx(
              "d-flex flex-column align-items",
              `align-items-start`
            )}
            style={{ width: "100%", marginBottom: "1.5rem" }}
          >
            <div
              className="d-flex align-items-center mb-2"
              style={{ width: "100%" }}
            >
              <>
                <div className="me-3">
                  <div className="symbol symbol-50px symbol-circle mb-2">
                    {user.picture ? (
                      <img alt="Pic" src={toAbsoluteUrl(user.picture)} />
                    ) : null}
                  </div>

                  <a
                    href="#"
                    className="fs-5 fw-bolder text-gray-900 text-hover-primary ms-2 me-2"
                  >
                    You
                  </a>

                  <span className="text-muted fs-7 mb-1">
                    {Functions.getPastTimeInfo(documentMessage.created_at)}
                  </span>
                </div>
              </>
            </div>

            <div
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
            >
              <MarkdownPreview
                className={clsx(
                  "p-5 rounded",
                  `bg-light-${state}`,
                  "text-dark fw-bold",
                  `text-start`
                )}
                style={{ fontSize: 14, minHeight: 60 }}
                source={decodedMessage}
                data-kt-element="message-text"
              />

              <div
                className="actions"
                style={{
                  display: "flex",
                  opacity: this.state.isHovered ? 1 : 0,
                  position: "relative",
                  left: 15,
                  justifyContent: "flex-end",
                }}
              >
                <button
                  onClick={() =>
                    this.props.onRewriteMessage({
                      ...this.props.documentMessage,
                      company_app_id:
                        this.props.documentMessage.company_app?.app_id,
                      company_avatar_id:
                        this.props.documentMessage.company_avatar?.id,
                    })
                  }
                  className="btn btn-icon w-auto me-2"
                  style={{
                    height: "auto",
                    padding: "5px 10px",
                  }}
                >
                  <KTSVG
                    path="media/icons/duotune/general/gen055.svg"
                    className="svg-icon-1"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>

        {this.renderMessageReplyItem(canUseLiveAvatar, documentMessage, index)}
      </>
    );
  }
}
