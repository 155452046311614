import { action, observable, when } from "mobx";
import { toast } from "react-toastify";

import {
  createCompanyModel,
  deleteCompanyModel,
  getModels,
  getCompanyModels,
  updateCompanyModel,
} from "../../helpers/api";
import { CompanyModel } from "../models/CompanyModel";
import {
  CompanyModelStatus,
  // FeatureType, // FeatureType importunu da kaldırdım
  UserActionLogStatus,
} from "../../helpers/Enums";
import { Model } from "../models/Model";

import RootStore from "./RootStore";
import stores from ".";

export type CompanyModelFilter = {
  status: CompanyModelStatus;
};

export default class CompanyModelStore {
  @observable models: Model[] = [];
  @observable.deep companyModels: CompanyModel[] = [];
  @observable.deep nonFilteredCompanyModels: CompanyModel[] = [];
  @observable isSetupModelModalOpened: boolean = false;
  @observable isLoading: boolean = false;

  constructor(rootStore: RootStore) {
    when(
      () =>
        rootStore.companyStore.selectedUserCompany !== undefined &&
        rootStore.companyAppStore.apps.length > 0,
      () => {
        this.getCompanyModels();
      }
    );
  }

  @action getModelById = (modelId: number): Model | undefined => {
    const model = this.models.find((model) => model.id === modelId);
    return model;
  };

  @action findCompanyModelById = (
    companyModelId: number
  ): CompanyModel | undefined => {
    // Convert companyModelId to string for comparison
    const companyIdStr = companyModelId.toString();

    // Ensure the comparison is made between strings
    return this.companyModels.find(
      (companyModel) => companyModel.id.toString() === companyIdStr
    );
  };

  @action getCompanyModels = async () => {
    this.isLoading = true;
    this.models = await getModels();

    if (stores.companyStore.selectedUserCompany !== undefined) {
      this.nonFilteredCompanyModels = await getCompanyModels(
        stores.companyStore.selectedUserCompany.id
      );

      this.filterAndSortCompanyModels();
    }
    this.isLoading = false;
  };

  @action filterAndSortCompanyModels = (
    searchTerm?: string,
    filter?: CompanyModelFilter
  ) => {
    this.isLoading = true;

    this.companyModels = this.nonFilteredCompanyModels;

    if (searchTerm)
      this.companyModels = this.nonFilteredCompanyModels.filter(
        (companyModel) => companyModel.model.name.includes(searchTerm)
      );

    if (filter) {
      this.companyModels = this.nonFilteredCompanyModels.filter(
        (companyModel) => companyModel.status === filter.status
      );
    }

    this.isLoading = false;
  };

  @action createCompanyModel(
    model: Model,
    credentials: string = ""
  ): Promise<CompanyModel> {
    return new Promise(async (resolve, reject) => {
      // Commenting out the permission check for connecting a model
      // if (
      //   !(await stores.userStore.checkSubscribedFeatureType(
      //     FeatureType.ConnectModel
      //   ))
      // ) {
      //   reject();
      //   return;
      // }

      if (!stores.companyStore.selectedUserCompany) {
        reject();
        return;
      }

      const actionToastid = toast.loading(`${model.name} connection pending`, {
        autoClose: 5000,
        position: "top-center",
        closeButton: true,
      });

      const userActionLog = await stores.userActionLogStore.createUserActionLog(
        `${model.name} connection`,
        "",
        0,
        0,
        UserActionLogStatus.InProgress
      );

      const createdCompanyModel = await createCompanyModel(
        model.id,
        stores.companyStore.selectedUserCompany.id,
        credentials,
        CompanyModelStatus.Connected,
        Date.now()
      );

      this.nonFilteredCompanyModels.push(createdCompanyModel);

      userActionLog.action_status = UserActionLogStatus.Success;
      userActionLog.action_result = `${model.name} activation successful.`;

      stores.userActionLogStore.updateUserActionLog(userActionLog);

      toast.update(actionToastid, {
        render: `${model.name} activation successful.`,
        type: "success",
        isLoading: false,
        autoClose: 3000,
        closeButton: true,
        position: "top-center",
      });

      resolve(createdCompanyModel);
    });
  }

  @action updateCompanyModel(companyModel: CompanyModel): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      await updateCompanyModel(companyModel);

      const existingCompanyModelIndex = this.nonFilteredCompanyModels.findIndex(
        (item) => item.id === companyModel.id
      );

      if (existingCompanyModelIndex !== -1) {
        this.nonFilteredCompanyModels[existingCompanyModelIndex] = companyModel;
      }

      this.filterAndSortCompanyModels();

      resolve(true);
    });
  }

  @action deleteSelectedCompanyModel(
    companyModel: CompanyModel
  ): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      await deleteCompanyModel(companyModel.id);

      this.nonFilteredCompanyModels = this.nonFilteredCompanyModels.filter(
        (item) => item.id !== companyModel.id
      );

      this.filterAndSortCompanyModels();

      resolve(true);
    });
  }
}
