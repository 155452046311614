import * as React from "react";
import clsx from "clsx";
import { observer } from "mobx-react";
import { Link, Navigate } from "react-router-dom";
import ReactGA from "react-ga4";

import { CompanyApp } from "../../../models/CompanyApp";
import NameAvatarStep from "../../AvatarWizard/NameAvatarStep";
import DepartmentSelectionStep from "../../AvatarWizard/DepartmentSelectionStep";
import stores from "../../../stores";
import DeparmentAppSelectionStep from "../../AvatarWizard/DeparmentAppSelectionStep";

interface State {
  tab: string;
  navigate: boolean;
  selectedDepartmentId: number | null;
  selectedAppIds: number[];
}

interface Props {
  companyApp?: CompanyApp;
}

const TABS = [
  "department-selection",
  "department-app-selection",
  "detail-your-avatar",
];

@observer
class CreateNewAvatar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tab: TABS[0],
      navigate: false,
      selectedDepartmentId: null,
      selectedAppIds: [],
    };
  }

  setTab = (tabName: string) => {
    this.setState({ tab: tabName });
  };

  handleNext = () => {
    const currentIndex = TABS.indexOf(this.state.tab);
    if (currentIndex < TABS.length - 1) {
      this.setTab(TABS[currentIndex + 1]);
    }
  };

  handleBack = () => {
    const currentIndex = TABS.indexOf(this.state.tab);
    if (currentIndex > 0) {
      this.setTab(TABS[currentIndex - 1]);
    }
  };

  handleSave = () => {
    this.setState({ navigate: true });
  };

  setSelectedDepartmentId = (id: number | null) => {
    this.setState({ selectedDepartmentId: id });
  };
  handleAppSelect = (selectedAppIds: number[]) => {
    this.setState({ selectedAppIds });
  };

  render() {
    const { tab, navigate, selectedDepartmentId, selectedAppIds } = this.state;
    const isEditMode = stores.wizardStore.isEditMode;

    if (navigate) {
      return <Navigate to="/avatars" />;
    }

    return (
      <div className="d-flex flex-column align-items-center min-vh-100">
        <div
          className="mb-3 mt-10 d-flex justify-content-between align-items-center"
          style={{
            width: "85%",
          }}
        >
          <div>
            <div className="fw-bold fs-2">
              {isEditMode ? "Editing Avatar" : "Creating Avatar"}
            </div>
          </div>
          <Link
            to="/avatars"
            className="text-primary fw-bold"
            onClick={
              isEditMode
                ? () =>
                    ReactGA.event({
                      category: "avatars_page",
                      action: "cancel_edit_avatar",
                      label: "cancel_edit_avatar_button",
                    })
                : () =>
                    ReactGA.event({
                      category: "avatars_page",
                      action: "cancel_creating_avatar",
                      label: "cancel_creating_avatar_button",
                    })
            }
          >
            <button className="btn btn-primary">Cancel</button>
          </Link>
        </div>

        <div
          className="card w-70"
          style={{ minWidth: "50vw", maxWidth: "80vw" }}
        >
          <div className="card-header justify-content-center overflow-auto">
            <ul
              className="nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap justify-content-center"
              role="tablist"
            >
              <li className="nav-item">
                <button
                  className={clsx("nav-link cursor-default", {
                    active: tab === "department-selection",
                    "text-active-primary": tab === "department-selection",
                  })}
                  role="tab"
                  style={{
                    pointerEvents: "none",
                    color:
                      tab === "department-selection" ? "#00ff00" : "inherit",
                  }}
                  disabled
                >
                  <p className="fs-5 px-15 pt-10">{"Department Selection"}</p>
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={clsx("nav-link cursor-default", {
                    active: tab === "department-app-selection",
                    "text-active-primary": tab === "department-app-selection",
                  })}
                  role="tab"
                  style={{
                    pointerEvents: "none",
                    color:
                      tab === "department-app-selection"
                        ? "#00ff00"
                        : "inherit",
                  }}
                  disabled
                >
                  <p className="fs-5 px-15 pt-10">
                    {"Department App Selection"}
                  </p>
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={clsx("nav-link cursor-default", {
                    active: tab === "detail-your-avatar",
                    "text-active-primary": tab === "detail-your-avatar",
                  })}
                  role="tab"
                  style={{
                    pointerEvents: "none",
                    color: tab === "detail-your-avatar" ? "#00ff00" : "inherit",
                  }}
                  disabled
                >
                  <p className="fs-5 px-15 pt-10">{"Detail Your Avatar"}</p>
                </button>
              </li>
            </ul>
          </div>
          <div className="card-body p-0">
            <div className="tab-content">
              <div
                className={clsx("tab-pane", {
                  active: tab === "department-selection",
                })}
              >
                <div className="row mb-10">
                  <DepartmentSelectionStep
                    onNext={this.handleNext}
                    onBack={this.handleBack}
                    setSelectedDepartmentId={this.setSelectedDepartmentId}
                  />
                </div>
              </div>
              <div
                className={clsx("tab-pane", {
                  active: tab === "department-app-selection",
                })}
              >
                <div className="row mb-10">
                  <DeparmentAppSelectionStep
                    onNext={this.handleNext}
                    onBack={this.handleBack}
                    onAppSelect={this.handleAppSelect}
                  />
                </div>
              </div>
              <div
                className={clsx("tab-pane", {
                  active: tab === "detail-your-avatar",
                })}
              >
                <div className="row mb-10">
                  <NameAvatarStep
                    onBack={this.handleBack}
                    onSave={this.handleSave}
                    isEditMode={isEditMode}
                    selectedDepartmentId={selectedDepartmentId}
                    selectedAppIds={selectedAppIds}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateNewAvatar;
