/* @flow */

import { Subscription } from "../../app/models/Subscription";

export default async function updateSubscription(
  subscriptionId: string,
  seats: number
): Promise<Subscription> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/update-subscription`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          subscriptionId: subscriptionId,
          seats: seats,
        }),
        credentials:
          process.env.REACT_APP_IS_DEV === "false" ? "include" : "same-origin",
      }
    );

    if (!response.ok) {
      throw new Error(`Failed to update subscription: ${response.statusText}`);
    }

    const data = await response.json();
    const subscription: Subscription = data;

    return subscription;
  } catch (error) {
    console.error("Error updating subscription:", error);
    throw error;
  }
}
