import React, { useState, useEffect, useMemo } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";

import { toAbsoluteUrl } from "../../../helpers";
import { AvatarLoading } from "./loading/AvatarLoading";

import stores from "../../stores";

interface NameAvatarStepProps {
  onBack: () => void;
  onSave: () => void;
  selectedDepartmentId: number | null;
  selectedAppIds: number[];
  isEditMode: boolean;
}

const NameAvatarStep: React.FC<NameAvatarStepProps> = ({
  onBack,
  onSave,
  selectedDepartmentId,
  isEditMode,
  selectedAppIds,
}) => {
  const { editingAvatar } = stores.wizardStore;

  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedLogoId, setSelectedLogoId] = useState<number | null>(null);

  const { selectedUserCompany } = stores.companyStore;
  const company_id = selectedUserCompany?.id;
  const logos = useMemo(
    () => [
      {
        id: 1,
        url: "/media/avatar-logos/customer_service_avatar.png",
        name: "Customer Service",
      },
      { id: 2, url: "/media/avatar-logos/exports_avatar.png", name: "Exports" },
      { id: 3, url: "/media/avatar-logos/finance_avatar.png", name: "Finance" },
      {
        id: 4,
        url: "/media/avatar-logos/hr_avatar.png",
        name: "Human Resources",
      },
      { id: 5, url: "/media/avatar-logos/it_avatar.png", name: "IT" },
      {
        id: 6,
        url: "/media/avatar-logos/randd_avatar.png",
        name: "Research and Development",
      },
      { id: 7, url: "/media/avatar-logos/sales_avatar.png", name: "Sales" },
      {
        id: 8,
        url: "/media/avatar-logos/software_developement_avatar.png",
        name: "Software Development",
      },
    ],
    []
  );

  useEffect(() => {
    if (isEditMode && editingAvatar) {
      setName(editingAvatar.name);
      setDescription(editingAvatar.description || "");
      const currentLogo = logos.find(
        (logo) => toAbsoluteUrl(logo.url) === editingAvatar.logo_url
      );
      if (currentLogo) {
        setSelectedLogoId(currentLogo.id);
      }
    }
  }, [editingAvatar, isEditMode, logos]);

  const resetFields = () => {
    setName("");
    setDescription("");
    setSelectedLogoId(null);
  };

  const createAvatar = async () => {
    const logoUrl = logos.find((logo) => logo.id === selectedLogoId)?.url || "";

    try {
      if (!company_id) {
        throw new Error("Company ID or selected model is undefined.");
      }

      const companyAvatar = await stores.companyAvatarStore.createCompanyAvatar(
        Number(company_id),
        name.trim(),
        description.trim(),
        logoUrl,
        Number(selectedDepartmentId),
        selectedAppIds
      );

      if (!companyAvatar) {
        throw new Error("Failed to create Avatar.");
      }

      resetFields();
      onSave();
    } catch (error) {
      setError("An error occurred during avatar creation.");
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    if (!selectedLogoId) {
      setError("Please select a logo.");
      setLoading(false);
      return;
    }

    const logoUrl = logos.find((logo) => logo.id === selectedLogoId)?.url || "";

    if (isEditMode && editingAvatar) {
      try {
        const companyAvatar = {
          id: Number(editingAvatar.id),
          company_id: Number(company_id),
          name: name.trim(),
          description: description.trim(),
          logo_url: logoUrl,
          status: editingAvatar.status,
          last_sync_at: editingAvatar.last_sync_at,
        };

        const updatedAvatar =
          await stores.companyAvatarStore.updateCompanyAvatar(
            companyAvatar,
            selectedDepartmentId,
            selectedAppIds
          );

        if (updatedAvatar) {
          await stores.companyAvatarStore.getCompanyAvatars();

          resetFields();
          onSave();
        } else {
          throw new Error("Failed to update avatar.");
        }
      } catch (error) {
        setError("Failed to update avatar.");
      }
    } else {
      await createAvatar();
    }
    setLoading(false);
  };

  return (
    <form
      className="form"
      onSubmit={handleSubmit}
      style={{ height: "auto", position: "relative" }}
    >
      <div className="w-100 mt-15 mb-7 ">
        <h4 className="fw-bold fs-2">Avatar Info</h4>
        <p className="text-muted fs-6">
          If you need more apps, please check out{" "}
          <Link
            to="/avatars"
            className="text-primary fw-bold"
            onClick={() =>
              ReactGA.event({
                category: "avatars_page",
                action: "go_avatar_page",
                label: "go_avatar_page_button",
              })
            }
          >
            Avatars Page.
          </Link>
        </p>
      </div>
      {loading && <AvatarLoading />}
      <div className="" style={{ height: "400px" }}>
        <div className="mb-10">
          <label className="fw-bolder fs-6 ms-1 mb-3">Avatar Name:</label>
          <input
            type="text"
            className="form-control form-control-lg form-control-solid"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter Avatar Name (Required)"
            required
          />
        </div>

        <div className="mb-10">
          <label className="fw-bolder fs-6 ms-1 mb-3">Description:</label>
          <input
            type="text"
            className="form-control form-control-lg form-control-solid"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter Description (Optional)"
          />
        </div>

        <div className="mb-10">
          <label className="fw-bolder fs-6 ms-1 mb-3">Select a Logo:</label>
          <div className="d-flex flex-wrap">
            {logos.map((logo) => (
              <div key={logo.id} className="m-2">
                <img
                  src={toAbsoluteUrl(logo.url)}
                  alt={logo.name}
                  className={`${
                    selectedLogoId === logo.id
                      ? "border border-primary border-3 border-dotted border-light"
                      : "border border-gray-300  border-dashed border-light"
                  }`}
                  style={{
                    cursor: "pointer",
                    width: "75px",
                    height: "75px",
                    borderRadius: "15px",
                  }}
                  onClick={() => {
                    setSelectedLogoId(logo.id);
                    ReactGA.event({
                      category: "avatars_page",
                      action: "select_avatar_logo",
                      label: "select_avatar_logo_button",
                    });
                  }}
                />
              </div>
            ))}
          </div>
        </div>

        {error && (
          <div className="row mb-10">
            <div className="col-lg-9 col-xl-4 offset-lg-3">
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        className="d-flex justify-content-between"
        style={{ marginTop: "40px" }}
      >
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            onBack();
            ReactGA.event({
              category: "avatars_page",
              action: "back_avatar_info_step",
              label: "back_avatar_info_step_button",
            });
          }}
        >
          <i className="ki-duotone ki-arrow-left fs-2">
            <span className="path1"></span>
            <span className="path2"></span>
          </i>
          Back
        </button>
        <button
          type="submit"
          className="btn btn-primary"
          disabled={!name.trim()}
          onClick={
            isEditMode
              ? () =>
                  ReactGA.event({
                    category: "avatars_page",
                    action: "update_avatar",
                    label: "update_avatar_button",
                  })
              : () =>
                  ReactGA.event({
                    category: "avatars_page",
                    action: "save_avatar",
                    label: "save_avatar_button",
                  })
          }
        >
          {isEditMode ? "Update" : "Save"}
          <i className="ki-duotone ki-arrow-right fs-2">
            <span className="path1"></span>
            <span className="path2"></span>
          </i>
        </button>
      </div>
    </form>
  );
};

export default observer(NameAvatarStep);
