import { Formik, Form } from "formik";
import * as Yup from "yup";
import { WizardContent } from "./WizardComponents";
import CompanyDetailsForm from "./CompanyDetailsForm";

interface CompanyDetailsProps {
  onNext: () => void;
  onBack: () => void;
  formData: {
    teamSize: string;
    name: string;
    logo: File | null;
    industry: string;
  };
  setFormData: (data: any) => void;
}

const CompanyDetails = ({
  onNext,
  onBack,
  formData,
  setFormData,
}: CompanyDetailsProps) => {
  return (
    <Formik
      initialValues={formData}
      enableReinitialize={true}
      validateOnMount={true}
      validationSchema={Yup.object({
        teamSize: Yup.string().required("Team size is required"),
        name: Yup.string().required("Company name is required"),
        logo: Yup.mixed().nullable(),
        industry: Yup.string().required("Industry is required"),
        companyDescription: Yup.string().nullable(),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        setFormData(values);
        setSubmitting(false);
        onNext();
      }}
    >
      {({ isValid, isSubmitting }) => (
        <Form
          className="form w-lg-500px mx-auto"
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          <WizardContent>
            <div style={{ paddingBottom: "120px" }}>
              <CompanyDetailsForm />
            </div>
          </WizardContent>
          <div
            className="fixed-bottom bg-white pb-20 pt-10"
            style={{ height: "80px" }}
          >
            <div className="container d-flex justify-content-between w-50">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onBack}
              >
                Back
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitting || !isValid}
              >
                Next
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CompanyDetails;
