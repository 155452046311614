import { action, observable, when } from "mobx";
import { toast } from "react-toastify";

import {
  createCompanyUser,
  createCompanyUserApp,
  createCompanyUserInvite,
  deleteCompanyUser,
  deleteCompanyUserInvite,
  deleteUser,
  getCompanyUserInvites,
  getCompanyUsers,
  sendInvitationMail,
  updateCompanyUser,
  updateSubscription,
  // updateCompanyUserApp,
} from "../../helpers/api";
import { CompanyApp } from "../models/CompanyApp";
import { CompanyUser } from "../models/CompanyUser";
import { UserRole } from "../models/UserRole";
import { CustomUser } from "../models/CustomUser";
import {
  CompanyUserAppStatus,
  CompanyUserStatus,
  FeatureType,
} from "../../helpers/Enums";
import { CompanyUserInvite } from "../models/CompanyUserInvite";
import i18n from "../../i18n";

import RootStore from "./RootStore";
import stores from ".";

export type CompanyUserFilter = {
  role: UserRole;
};

export default class CompanyUserStore {
  @observable companyUsers: CompanyUser[] = [];
  @observable selectedCompanyUserInvite?: CompanyUserInvite;
  @observable filteredCustomUsers: CustomUser[] = [];
  @observable selectedCompanyUser?: CompanyUser;
  @observable selectedCompanyUsers: CompanyUser[] = [];
  @observable nonFilteredCompanyUsers: CompanyUser[] = [];
  @observable companyUserInvites: CompanyUserInvite[] = [];
  @observable customUsers: CustomUser[] = [];
  @observable isCompanyUserEditModalOpened: boolean = false;
  @observable isCompanyUserInvitesManageModalOpened: boolean = false;
  @observable isCompanyUserManageAppsModalOpened: boolean = false;
  @observable isCompanyUserAddAppModalOpened: boolean = false;
  @observable isCompanyUserDeleteModalOpened: boolean = false;
  @observable isCompanyUserInviteDeleteModalOpened: boolean = false;
  @observable isAddCompanyUsersToAppsModalOpened: boolean = false;
  @observable isLoading: boolean = false;

  constructor(rootStore: RootStore) {
    when(
      () => rootStore.companyStore.selectedUserCompany !== undefined,
      () => {
        this.getCompanyUsers();
        this.getCompanyUserInvites();
        this.getCustomUsers();
      }
    );
  }

  @action getCompanyUsers = async (): Promise<CompanyUser[]> => {
    this.isLoading = true;

    this.nonFilteredCompanyUsers = [];
    this.companyUsers = [];
    this.selectedCompanyUser = undefined;
    this.selectedCompanyUsers = [];

    if (stores.companyStore.selectedUserCompany !== undefined) {
      this.nonFilteredCompanyUsers = await getCompanyUsers(
        stores.companyStore.selectedUserCompany.id
      );

      this.filterAndSortCompanyUsers();
    }
    this.getCustomUsers();
    this.isLoading = false;

    return this.nonFilteredCompanyUsers;
  };

  @action getCompanyUserInvites = async () => {
    if (stores.companyStore.selectedUserCompany) {
      this.companyUserInvites = await getCompanyUserInvites(
        stores.companyStore.selectedUserCompany.id
      );
    }
    this.getCustomUsers();
  };

  @action getCustomUsers = async () => {
    if (stores.companyStore.selectedUserCompany) {
      const companyUserInvites = await getCompanyUserInvites(
        stores.companyStore.selectedUserCompany.id
      );
      const companyUsers = await getCompanyUsers(
        stores.companyStore.selectedUserCompany.id
      );

      const customUserInvites = companyUserInvites
        .filter(
          (invite) =>
            invite.name && invite.email && invite.status !== "Approved"
        )
        .map((invite) => ({
          id: invite.id,
          name: invite.name || "",
          email: invite.email || "",
          status: invite.status,
          role_id: invite.role_id,
        }));

      const customUsers = companyUsers
        .filter((user) => user.user.name && user.user.email)
        .map((user) => ({
          id: user.id,
          name: user.user.name || "",
          email: user.user.email || "",
          status: user.status,
          role_id: user.role_id,
        }));

      this.customUsers = [...customUsers, ...customUserInvites];

      this.filterAndSortCustomUsers();
    }
  };

  @action filterAndSortCustomUsers = (searchTerm?: string, filter?: number) => {
    let filteredUsers = this.customUsers;

    if (searchTerm) {
      filteredUsers = filteredUsers.filter((customUser) =>
        customUser.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (filter) {
      filteredUsers = filteredUsers.filter(
        (customUser) => customUser.role_id === filter
      );
    }

    this.filteredCustomUsers = filteredUsers;
  };

  @action filterAndSortCompanyUsers = (
    searchTerm?: string,
    filter?: CompanyUserFilter
  ) => {
    this.isLoading = true;

    this.selectedCompanyUser = undefined;
    this.selectedCompanyUsers = [];

    this.companyUsers = this.nonFilteredCompanyUsers;

    if (searchTerm)
      this.companyUsers = this.nonFilteredCompanyUsers.filter((companyUser) =>
        companyUser.user.name!.toLowerCase().includes(searchTerm.toLowerCase())
      );

    if (filter) {
      this.companyUsers = this.nonFilteredCompanyUsers.filter(
        (companyUser) =>
          companyUser.role_id.toString() === filter.role.id.toString()
      );
    }

    this.isLoading = false;
  };

  @action createCompanyUser(
    user_id: number,
    company_id: number,
    role_id: number,
    status: CompanyUserStatus
  ): Promise<CompanyUser> {
    return new Promise(async (resolve, reject) => {
      try {
        const createdCompanyUser = await createCompanyUser(
          user_id,
          company_id,
          role_id,
          status
        );

        const companyUsers = await getCompanyUsers(company_id);

        const totalUsersCount = companyUsers.length;

        if (stores.userStore.currentUser.stripeProfile) {
          const subscription = stores.userStore.currentUser.subscription;

          if (subscription && subscription.items.data.length > 0) {
            try {
              await updateSubscription(subscription.id, totalUsersCount);
            } catch (error) {
              console.error("Failed to update subscription:", error);
            }
          } else {
            console.error("Subscription or subscription items not found.");
          }
        }

        this.nonFilteredCompanyUsers.push(createdCompanyUser);
        this.filterAndSortCompanyUsers();

        resolve(createdCompanyUser);
      } catch (error) {
        console.error("Failed to create company user:", error);
        reject(error);
      }
    });
  }

  @action batchInviteAppUsers(users: string[]): Promise<CompanyUserInvite[]> {
    return new Promise(async (resolve, reject) => {
      const companyUserInvites: CompanyUserInvite[] = [];

      for (let index = 0; index < users.length; index++) {
        const user = users[index];

        if (stores.companyStore.selectedUserCompany) {
          const companyUserInvite = await this.createCompanyUserInvite(
            "",
            user,
            stores.companyStore.selectedUserCompany.id,
            2,
            CompanyUserStatus.InProgress
          );

          if (companyUserInvite) companyUserInvites.push(companyUserInvite);
        }
      }

      resolve(companyUserInvites);
    });
  }

  @action createCompanyUserInvite(
    name: string,
    email: string,
    company_id: number,
    role_id: number,
    status: CompanyUserStatus
  ): Promise<CompanyUserInvite | undefined> {
    return new Promise(async (resolve, reject) => {
      if (stores.userStore.isFreeUser && this.companyUserInvites.length >= 2) {
        toast.error(i18n.ToastMessages.companyUserInviteLimitError, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeButton: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        resolve(undefined);
        return;
      }

      // Check if the feature type is subscribed
      if (
        !(await stores.userStore.checkSubscribedFeatureType(
          FeatureType.CompanyUser
        ))
      ) {
        reject();
        return;
      }

      // Check if the email is already invited or is the current user's email
      if (
        this.companyUserInvites.findIndex(
          (companyUserInvite) => companyUserInvite.email === email
        ) !== -1 ||
        email === stores.userStore.currentUser.email
      ) {
        toast.error(i18n.ToastMessages.userAlreadyInvitedError, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeButton: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        resolve(undefined);
        return;
      }

      // Create the company user invite
      const createdCompanyUserInvite = await createCompanyUserInvite(
        name,
        email,
        company_id,
        role_id,
        status,
        Date.now()
      );

      // Add the new invite to the list
      this.companyUserInvites.push(createdCompanyUserInvite);

      // Send the invitation email
      await sendInvitationMail(
        email,
        name,
        stores.userStore.currentUser.name
          ? stores.userStore.currentUser.name
          : "",
        stores.companyStore.selectedUserCompany
          ? stores.companyStore.selectedUserCompany.name
          : ""
      );

      // Resolve with the created invite
      resolve(createdCompanyUserInvite);

      // Show success toast message
      toast.success(i18n.ToastMessages.userInviteSuccess, {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      this.getCustomUsers();
    });
  }

  @action updateCompanyUserInvite(
    companyUserInvite: CompanyUserInvite
  ): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const result = await this.updateCompanyUserInvite(companyUserInvite);

      const existingCompanyUserInviteIndex = this.companyUserInvites.findIndex(
        (item) => item.id === companyUserInvite.id
      );

      if (existingCompanyUserInviteIndex !== -1) {
        this.companyUserInvites[existingCompanyUserInviteIndex] =
          companyUserInvite;
      }

      resolve(result);
    });
  }

  @action updateCompanyUser(companyUser: CompanyUser): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      await updateCompanyUser(companyUser);

      const existingCompanyUserIndex = this.nonFilteredCompanyUsers.findIndex(
        (item) => item.id === companyUser.id
      );

      if (existingCompanyUserIndex !== -1) {
        this.nonFilteredCompanyUsers[existingCompanyUserIndex] = companyUser;
      }

      this.filterAndSortCompanyUsers();

      resolve(true);
    });
  }

  @action deleteCompanyUser(companyUserId: number): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const userId = stores.companyUserStore.companyUsers.find(
        (item) => item.id === companyUserId
      )?.user_id;

      if (userId?.toString() === stores.userStore.currentUser.id.toString()) {
        toast.error(i18n.ToastMessages.currentUserDeleteError, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeButton: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        resolve(false);

        return;
      }

      await deleteCompanyUser(companyUserId);

      this.nonFilteredCompanyUsers = this.nonFilteredCompanyUsers.filter(
        (item) => item.id !== companyUserId
      );

      if (stores.userStore.currentUserInvitation)
        await deleteCompanyUserInvite(
          stores.userStore.currentUserInvitation.id
        );

      this.filterAndSortCompanyUsers();

      resolve(true);
    });
  }

  @action deleteSelectedCompanyUsers(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      this.selectedCompanyUsers.forEach(async (companyUser) => {
        if (
          companyUser.user_id.toString() ===
          stores.userStore.currentUser.id.toString()
        ) {
          toast.error(i18n.ToastMessages.someUsersDeleteError, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeButton: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          resolve(false);

          return;
        }
        const selectedInviteUser = this.companyUserInvites.find(
          (inviteUser) => inviteUser.email === companyUser.user.email
        );

        if (selectedInviteUser) {
          await deleteCompanyUserInvite(selectedInviteUser.id);
        }
        await deleteCompanyUser(companyUser.id);
        await deleteUser(companyUser.user_id);

        this.nonFilteredCompanyUsers = this.nonFilteredCompanyUsers.filter(
          (item) => item.id !== companyUser.id
        );

        this.filterAndSortCompanyUsers();

        this.selectedCompanyUsers = this.selectedCompanyUsers.filter(
          (item) => item.id !== companyUser.id
        );
      });

      resolve(true);
    });
  }

  @action deleteCompanyUserInvite = async (inviteId: number) => {
    try {
      await deleteCompanyUserInvite(inviteId);

      this.companyUserInvites = this.companyUserInvites.filter(
        (invite) => invite.id !== inviteId
      );

      toast.success(i18n.ToastMessages.deleteInviteSuccess, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error("Failed to delete invite:", error);
      toast.error(i18n.ToastMessages.deleteInviteError, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  @action deleteSelectedCompanyUser(
    companyUser: CompanyUser
  ): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        if (
          companyUser.user_id.toString() ===
          stores.userStore.currentUser.id.toString()
        ) {
          toast.error(i18n.ToastMessages.someUsersDeleteError, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeButton: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          resolve(false);
          return;
        }

        const selectedInviteUser = this.companyUserInvites.find(
          (inviteUser) => inviteUser.email === companyUser.user.email
        );

        if (selectedInviteUser) {
          await deleteCompanyUserInvite(selectedInviteUser.id);

          this.companyUserInvites = await getCompanyUserInvites(
            selectedInviteUser.company_id
          );
        }

        await deleteCompanyUser(companyUser.id);
        await deleteUser(companyUser.user_id);

        this.nonFilteredCompanyUsers = this.nonFilteredCompanyUsers.filter(
          (item) => item.id !== companyUser.id
        );

        this.filterAndSortCompanyUsers();

        this.selectedCompanyUsers = this.selectedCompanyUsers.filter(
          (item) => item.id !== companyUser.id
        );

        if (stores.userStore.currentUser.stripeProfile) {
          const subscriptionId = stores.userStore.currentUser.subscription?.id;
          if (stores.companyStore.selectedUserCompany) {
            if (subscriptionId) {
              const companyUsers = await getCompanyUsers(
                stores.companyStore.selectedUserCompany.id
              );

              const totalUsersCount = companyUsers.length;

              const currentSeats = totalUsersCount;
              await updateSubscription(subscriptionId, currentSeats);
            }
          } else {
            console.error("Selected company is undefined.");
          }
        }

        resolve(true);
      } catch (error) {
        console.error("Failed to delete selected company user:", error);
        reject(error);
      }
    });
  }

  @action addCompanyUsersToApps(
    selectedCompanyApps: CompanyApp[],
    selectedUsers: CompanyUser[]
  ): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      for (let index = 0; index < selectedCompanyApps.length; index++) {
        const companyApp = selectedCompanyApps[index];

        for (let index = 0; index < selectedUsers.length; index++) {
          const companyUser = selectedUsers[index];

          if (
            companyApp.company_user_apps.findIndex(
              (item) =>
                item.app_id.toString() === companyApp.app_id.toString() &&
                item.user_id === companyUser.user_id
            ) === -1
          ) {
            if (companyUser.user.email) {
              const companyAppIndex =
                stores.companyAppStore.nonFilteredCompanyApps.findIndex(
                  (item) => item.id === companyApp.id
                );

              const companyUserApp = await createCompanyUserApp(
                companyUser.user_id,
                companyApp.app_id,
                companyApp.id,
                "",
                CompanyUserAppStatus.InProgress,
                Date.now()
              );

              stores.companyAppStore.nonFilteredCompanyApps[
                companyAppIndex
              ].company_user_apps.push(companyUserApp);
            }
          } else {
            toast.error(i18n.ToastMessages.userAlreadyInvitedError, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeButton: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });

            resolve(false);
          }
        }

        stores.companyAppStore.filterAndSortCompanyApps();
      }

      resolve(true);
    });
  }
}
