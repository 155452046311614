/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { observer } from "mobx-react";
import ReactGA from "react-ga4";

import stores from "../../stores";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import { Storage } from "../../models/Storage";
import { CompanyStorageStatus } from "../../../helpers/Enums";
import i18n from "../../../i18n";
import { CompanyStorage } from "../../models/CompanyStorage";

import SetupStorageModal from "./SetupStorageModal";
import AlertModal from "./AlertModal";

type Props = {};

type State = {
  selectedStorage?: Storage;
  isSetupStorageModalOpened: boolean;
  isAlertModalOpened: boolean;
};

@observer
export default class CompanyStoragesPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isSetupStorageModalOpened: false,
      isAlertModalOpened: false,
    };
  }

  private handleActivateClick = async (credentials: string) => {
    const { selectedStorage } = this.state;

    if (selectedStorage) {
      this.setState({ isSetupStorageModalOpened: false });

      const createdCompanyStorage =
        await stores.companyStorageStore.createCompanyStorage(
          selectedStorage,
          credentials
        );

      if (createdCompanyStorage) {
        this.setState({ selectedStorage: undefined });
      }
    }
  };

  private handleUpdateClick = async (
    companyStorage: CompanyStorage,
    credentials: string
  ) => {
    this.setState({ isSetupStorageModalOpened: false });

    companyStorage.credentials = credentials;
    await stores.companyStorageStore.updateCompanyStorage(companyStorage);

    this.setState({ selectedStorage: undefined });
  };

  private handleDeactivateClick = async (companyStorage: CompanyStorage) => {
    this.setState({ isSetupStorageModalOpened: false });

    await stores.companyStorageStore.deleteSelectedCompanyStorage(
      companyStorage
    );

    this.setState({ selectedStorage: undefined });
  };

  private renderStorageStatusBadge = (status: CompanyStorageStatus) => {
    return (
      <>
        {status === CompanyStorageStatus.Connected ? (
          <span className="badge badge-light-primary fs-8 ms-2 mb-1 fw-bold">
            Connected
          </span>
        ) : status === CompanyStorageStatus.InProgress ? (
          <span className="badge badge-light-warning fs-8 ms-2 mb-1 fw-bold">
            In Progress
          </span>
        ) : status === CompanyStorageStatus.Failed ? (
          <span className="badge badge-light-danger fs-8 ms-2 mb-1 fw-bold">
            Failed
          </span>
        ) : (
          <span className="badge badge-light-danger fs-8 ms-2 mb-1 fw-bold">
            {i18n.AppInvitationsTable.notConnected}
          </span>
        )}
      </>
    );
  };

  private handleStorageClick = async (storage: Storage) => {
    // const hasStorageAccess = await stores.userStore.checkSubscribedFeatureType(
    //   FeatureType.CompanyStorage
    // );

    ReactGA.event({
      category: "company_storages_page",
      action: "set_up_storage",
      label: "set_up_storage_button",
    });
    // if (!hasStorageAccess) {
    //   toast.error(i18n.ToastMessages.maxStorageError, {
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeButton: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "light",
    //   });
    //   return;
    // }

    this.setState({
      selectedStorage: storage,
      isSetupStorageModalOpened: true,
    });
  };

  private renderStorageItem = (storage: Storage) => {
    const companyStorage = stores.companyStorageStore.companyStorages.find(
      (companyStorage) =>
        companyStorage.storage_id.toString() === storage.id.toString()
    );

    return (
      <div
        key={storage.id}
        className="d-flex align-items-sm-center mb-7 pb-7 border-bottom"
      >
        {/* begin::Symbol */}
        <div className="me-2 position-relative">
          <div className="symbol symbol-90px me-1">
            <span className="symbol-label">
              <img
                src={toAbsoluteUrl(storage.logo)}
                className="align-self-center"
                style={{ width: 70 }}
                alt=""
              />
            </span>
          </div>
        </div>
        {/* end::Symbol */}
        {/* begin::Title */}
        <div className="d-flex flex-row-fluid flex-wrap align-items-center">
          <div className="flex-grow-1 me-2">
            <span className="text-gray-800 fw-bold text-hover-primary fs-4">
              {storage.name}
            </span>

            {this.renderStorageStatusBadge(
              companyStorage ? companyStorage.status : CompanyStorageStatus.None
            )}

            {companyStorage?.is_default ? (
              <span className="badge badge-light-info fs-8 ms-2 mb-1 fw-bold">
                Default
              </span>
            ) : null}

            <span
              style={{ maxWidth: "35vw" }}
              className="text-muted fw-semibold d-block pt-1"
              dangerouslySetInnerHTML={{ __html: storage.description }}
            ></span>
          </div>

          <div className="d-flex ms-3">
            {companyStorage &&
            companyStorage.status === CompanyStorageStatus.Connected ? (
              <button
                className="btn btn-flex flex-center bg-body btn-color-gray-700 btn-active-color-primary w-40px w-md-auto h-40px px-0 px-md-6"
                onClick={() => {
                  this.setState({ selectedStorage: storage }, () => {
                    this.setState({ isSetupStorageModalOpened: true });
                  });
                }}
              >
                <KTSVG
                  path={"/media/icons/duotune/art/art005.svg"}
                  className="svg-icon svg-icon-x text-gray me-3"
                />

                <span className="d-none d-md-inline">Edit</span>
              </button>
            ) : (
              <button
                className="btn btn-flex flex-center bg-body btn-color-gray-700 btn-active-color-primary w-40px w-md-auto h-40px px-0 px-md-6"
                onClick={() => this.handleStorageClick(storage)}
              >
                <KTSVG
                  path={"/media/icons/duotune/coding/cod001.svg"}
                  className="svg-icon svg-icon-2x text-primary me-3"
                />

                <span className="d-none d-md-inline">Set up</span>
              </button>
            )}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { selectedStorage, isSetupStorageModalOpened, isAlertModalOpened } =
      this.state;

    return (
      <div className="d-flex flex-column flex-lg-row">
        <div className="flex-lg-row-fluid">
          <div className="card ms-8 me-12">
            <div className="card-header align-items-center border-0 mt-4">
              <h3 className="card-title align-items-start flex-column">
                <span className="fw-bolder text-dark fs-2">
                  Storage Providers
                </span>

                <span className="text-gray-600 mt-4 fw-semibold fs-6">
                  Your data will by default be securely encrypted and stored on
                  CompanyDNA's AWS S3 storage.
                </span>

                <span className="text-gray-600 mt-2 mb-2 fw-semibold fs-6">
                  If you prefer, you can connect your own AWS S3 storage,
                  ensuring that your data is stored on your own AWS S3 storage.
                </span>
              </h3>
            </div>

            <div className="card-body pt-5">
              {!stores.companyStorageStore.isLoading
                ? stores.companyStorageStore.storages
                    .filter((storage) => storage.published)
                    .map((storage) => this.renderStorageItem(storage))
                : null}
            </div>
          </div>
        </div>

        {isSetupStorageModalOpened && selectedStorage ? (
          <SetupStorageModal
            storage={selectedStorage}
            onCancel={() => this.setState({ isSetupStorageModalOpened: false })}
            onActivate={this.handleActivateClick}
            onUpdate={this.handleUpdateClick}
            onDeactivate={this.handleDeactivateClick}
          />
        ) : null}

        {isAlertModalOpened ? (
          <AlertModal
            onCancel={() => this.setState({ isAlertModalOpened: false })}
          />
        ) : null}
      </div>
    );
  }
}
