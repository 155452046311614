import React, { ReactNode } from "react";
import PropTypes from "prop-types";

interface WizardLayoutProps {
  children: ReactNode;
  currentStep: number;
}

export const WizardLayout: React.FC<WizardLayoutProps> = ({
  children,
  currentStep,
}) => (
  <div className="d-flex flex-column h-100">
    <WizardHeader currentStep={currentStep} />
    <div className="flex-grow-1 overflow-auto px-5">{children}</div>
  </div>
);

WizardLayout.propTypes = {
  children: PropTypes.node.isRequired,
  currentStep: PropTypes.number.isRequired,
};

interface WizardHeaderProps {
  currentStep: number;
}

export const WizardHeader: React.FC<WizardHeaderProps> = ({ currentStep }) => {
  const steps = [
    { title: "Company Details", step: 1 },
    { title: "Select Plan", step: 2 },
    { title: "Summary", step: 3 },
  ];

  return (
    <div className="stepper stepper-links stepper-horizontal bg-white pb-5 pt-5 d-flex">
      <div className="stepper-nav w-100 d-flex justify-content-center">
        {steps.map(({ title, step }) => (
          <div
            key={step}
            className={`stepper-item ${step < currentStep ? "completed" : ""} ${
              step === currentStep ? "current" : ""
            }`}
            data-kt-stepper-element="nav"
          >
            <div className="stepper-label">
              <span className="stepper-title">{title}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

WizardHeader.propTypes = {
  currentStep: PropTypes.number.isRequired,
};

interface WizardContentProps {
  children: ReactNode;
}

export const WizardContent: React.FC<WizardContentProps> = ({ children }) => (
  <div className="flex-grow-1 overflow-auto px-5">{children}</div>
);

WizardContent.propTypes = {
  children: PropTypes.node.isRequired,
};

interface WizardFooterProps {
  onBack: () => void;
  onNext: () => void;
  currentStep: number;
  isLastStep: boolean;
}

export const WizardFooter: React.FC<WizardFooterProps> = ({
  onBack,
  onNext,
  currentStep,
  isLastStep,
}) => (
  <div
    className="fixed-bottom bg-gray-300 py-3"
    style={{
      backgroundColor: "#f8f9fa",
      position: "fixed",
      bottom: 0,
      width: "100%",
      zIndex: 10,
      padding: "20px 0",
    }}
  >
    <div className="container d-flex justify-content-between w-50 mx-auto">
      {currentStep > 1 && (
        <button className="btn btn-secondary" onClick={onBack}>
          Back
        </button>
      )}
      {!isLastStep && (
        <button className="btn btn-primary" onClick={onNext}>
          {currentStep === 2 ? "Continue to Summary" : "Next"}
        </button>
      )}
      {isLastStep && (
        <button className="btn btn-success" onClick={onNext}>
          Finish
        </button>
      )}
    </div>
  </div>
);

WizardFooter.propTypes = {
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  isLastStep: PropTypes.bool.isRequired,
};
