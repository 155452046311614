import axios from "axios";

const CLOUDFLARE_DOMAIN = "imagedelivery.net";

/**
 * Uploads a file to the server and returns the image URL.
 * @param file - The file to upload.
 * @returns The uploaded image URL or null if the upload fails.
 */
export async function uploadFile(file: File): Promise<string | null> {
  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/upload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.data.imageUrl) {
      return response.data.imageUrl;
    } else {
      console.error("Upload failed:", response.data.error);
      return null;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Error uploading file:", error.message);
      if (error.response) {
        console.error("Response data:", error.response.data);
      }
    } else {
      console.error("An unexpected error occurred:", error);
    }
    return null;
  }
}

/**
 * Deletes a file from the server given its URL.
 * @param imageUrl - The URL of the image to delete.
 * @returns True if the deletion was successful, false otherwise.
 */
export async function deleteFile(imageUrl: string): Promise<boolean> {
  try {
    if (!imageUrl.includes(CLOUDFLARE_DOMAIN)) {
      return true;
    }

    const response = await axios({
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}/delete`,
      data: { imageUrl },
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.data.message) {
      return true;
    } else {
      console.error("Deletion failed:", response.data.error);
      return false;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Error deleting file:", error.message);
      if (error.response) {
        console.error("Response data:", error.response.data);
      }
    } else {
      console.error("An unexpected error occurred:", error);
    }
    return false;
  }
}
