/* @flow */
import { io } from "socket.io-client";
import stores from "../app/stores";

type MessageHandler = (message: any) => void;

class SocketHelper {
  private socketIo?: any;
  private messageHandlers: MessageHandler[] = [];
  private pingInterval?: NodeJS.Timeout;
  private lastPongTime: number = Date.now();
  private reconnectAttempts: number = 0;
  private maxReconnectAttempts: number = 5;

  init = () => {
    if (process.env.REACT_APP_WEB_SOCKET_URL) {
      this.socketIo = io(process.env.REACT_APP_WEB_SOCKET_URL, {
        path: "/socket.io",
        transports: ["websocket", "polling"],
        secure: true,
        reconnectionAttempts: 5,
        timeout: 20000,
      });

      this.socketIo.on("json", (json: any) => {
        this.messageHandlers.forEach((handler) => handler(json));
      });

      this.socketIo.on("clientId", (clientId: any) => {
        stores.userStore.socketClientId = clientId;
      });

      this.socketIo.on("pong", () => {
        this.lastPongTime = Date.now();
      });

      this.socketIo.on("disconnect", () => {
        this.handleReconnect();
      });

      this.startPing();
    }
  };

  startPing = () => {
    this.pingInterval = setInterval(() => {
      if (this.socketIo && this.socketIo.connected) {
        const currentTime = Date.now();

        if (currentTime - this.lastPongTime > 10000) {
          console.warn("No pong received from server. Reconnecting...");
          this.handleReconnect();
          this.clearPingInterval();
        } else {
          this.socketIo.emit("ping");
        }
      }
    }, 5000);
  };

  handleReconnect = () => {
    if (this.reconnectAttempts < this.maxReconnectAttempts) {
      this.reconnectAttempts += 1;
      if (this.pingInterval) this.clearPingInterval();
      this.socketIo.connect();
      this.startPing();
    } else {
      console.error(
        "Max reconnect attempts reached. Stopping reconnection attempts."
      );
      this.clearPingInterval();
    }
  };

  clearPingInterval = () => {
    if (this.pingInterval) {
      clearInterval(this.pingInterval);
      this.pingInterval = undefined;
    }
  };

  close = () => {
    if (this.socketIo) {
      this.socketIo.disconnect();
    }
    this.clearPingInterval();
  };

  addMessageHandler(handler: MessageHandler) {
    this.messageHandlers.push(handler);
  }

  removeMessageHandler(handler: MessageHandler) {
    this.messageHandlers = this.messageHandlers.filter((h) => h !== handler);
  }
}

const socketHelper = new SocketHelper();

export default socketHelper;
