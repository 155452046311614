/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { AppDepartment } from "../../app/models/AppDepartment";
import Functions from "../Functions";

export default async function getAppDepartments(): Promise<AppDepartment[]> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const query = gql`
      query AppDepartmentsQuery {
        appDepartmentList {
          id
          app_id
          department_id
          app {
            id
            name
            important
            limitations
            params
            description
            integration_id
            site_url
            token_info_url
            logo
            published
            is_source_available
            status
          }
          department {
            id
            name
            description
            color
          }
        }
      }
    `;

    client
      .query({
        query: query,
        fetchPolicy: "no-cache",
      })
      .then((AppDepartmentsQueryResult: any) => {
        const rawAppDepartments = get(
          AppDepartmentsQueryResult,
          "data.appDepartmentList",
          []
        );

        const appDepartments: AppDepartment[] = rawAppDepartments.map(
          (appDepartment: any) => ({
            ...appDepartment,
            app: {
              ...appDepartment.app,
              params: Functions.transformStringToConnectionParam(
                appDepartment.app.params
              ),
            },
          })
        );

        resolve(appDepartments);
      })
      .catch((error: any) => {
        console.error(error);

        resolve([]);
      });
  });
}
