// @flow

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { Company } from "../../app/models/Company";

export default async function createCompany(
  user_id: number,
  name: string,
  logo: string | undefined,
  teamSize: string | undefined,
  industry: string | undefined,
  plan: string | undefined,
  timezone: string
): Promise<Company> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const mutation = gql`
    mutation {
      createCompany(
        user_id: ${user_id}
        name: "${name}"
        logo: "${logo}"
        teamSize: "${teamSize}"
        industry: "${industry}"
        plan: "${plan}"
        created_at: ${Date.now()}
        is_index_created: 0
        index_updated_at: 0
        timezone: "${timezone}"
      ) {
        id
        user_id
        name
        logo
        created_at
        teamSize
        industry
        plan
        is_index_created
        index_updated_at
        timezone
      }
    }
  `;

    client
      .mutate({ mutation })
      .then((createCompanyMutationResult: any) => {
        const company: Company = get(
          createCompanyMutationResult,
          "data.createCompany",
          null
        );

        if (company) {
          resolve(company);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        console.error(error);

        reject();
      });
  });
}
