/* @flow */

const cancelSubscription = async (
  subscriptionId: string
): Promise<{ success: boolean }> => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/cancel-subscription`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ subscriptionId }),
          credentials:
            process.env.REACT_APP_IS_DEV === "false"
              ? "include"
              : "same-origin",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to cancel subscription");
      }

      resolve({ success: true });
    } catch (error) {
      console.error("Error canceling subscription:", error);
      reject(error);
    }
  });
};

export default cancelSubscription;
