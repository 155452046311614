/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { observer } from "mobx-react";
import ReactGA from "react-ga4";

import { Storage } from "../../models/Storage";

import { checkStorageApiKey } from "../../../helpers/api";
import { CompanyStorage } from "../../models/CompanyStorage";
import stores from "../../stores";
import { KTIcon } from "../../../helpers";

interface Props {
  storage: Storage;
  companyStorage?: CompanyStorage;
  onCancel: () => void;
  onActivate: (apiKey: string) => void;
  onUpdate: (companyStorage: CompanyStorage, apiKey: string) => void;
  onDeactivate: (companyStorage: CompanyStorage) => void;
}

interface State {
  companyStorage?: CompanyStorage;
  credentials: string;
  activationMessage: string;
  hasError: boolean;
  isLoading: boolean;
  isFileLoaded: boolean;
}

@observer
export default class SetupStorageModal extends Component<Props, State> {
  private fileInput: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      credentials: "",
      activationMessage: "",
      hasError: false,
      isLoading: false,
      isFileLoaded: false,
    };
  }

  componentWillMount() {
    const { storage } = this.props;

    const companyStorage = stores.companyStorageStore.companyStorages.find(
      (companyStorage) =>
        companyStorage.storage_id.toString() === storage.id.toString()
    );

    if (companyStorage)
      this.setState({
        companyStorage: companyStorage,
        credentials: companyStorage.credentials,
      });
  }

  private handleTestClick = async (apiKey: string) => {
    const { storage } = this.props;
    ReactGA.event({
      category: "company_storages_page",
      action: "test_storages",
      label: "test_storages_button",
    });

    this.setState({ isLoading: true });

    if (stores.companyStore.selectedUserCompany) {
      const result = await checkStorageApiKey(
        stores.companyStore.selectedUserCompany.id,
        storage,
        apiKey
      );

      this.setState({
        activationMessage: result.message,
        hasError: !result.success,
        isLoading: false,
      });
    }
  };

  private handleFileSelect = async (event: any) => {
    const file = event.target.files[0];

    ReactGA.event({
      category: "company_storages_page",
      action: "file_select_for_storage",
      label: "file_select_for_storage_button",
    });

    if (file && file.type === "application/json") {
      const reader = new FileReader();

      reader.onload = async (e) => {
        try {
          if (e.target && e.target.result) {
            //@ts-ignore
            const result = JSON.parse(e.target.result);

            this.setState({
              credentials: encodeURIComponent(JSON.stringify(result)),
              isFileLoaded: true,
            });
          }
        } catch (err) {
          this.setState({ isFileLoaded: false });
        }
      };

      reader.readAsText(file);
    } else {
      this.setState({ isFileLoaded: false });
    }
  };

  render() {
    const { storage } = this.props;
    const {
      companyStorage,
      credentials,
      isLoading,
      activationMessage,
      hasError,
      isFileLoaded,
    } = this.state;

    return (
      <>
        <div
          className="modal fade show d-block"
          id="kt_modal_add_company_users_apps"
          role="dialog"
          tabIndex={-1}
          aria-modal="true"
        >
          <div className="modal-dialog modal-dialog-centered mw-500px">
            <div className="modal-content">
              <div className="modal-header pb-0 border-0">
                <h3 className="align-items-start flex-row">
                  <span className="fw-bold text-dark">{`Setup ${storage.name}`}</span>
                </h3>
              </div>

              <div className="modal-body py-lg-10 px-lg-10">
                <div className="current" data-kt-stepper-element="content">
                  <span className="text-muted mt-1 fw-semibold fs-6">
                    We'll never use your credentials for any purpose other than
                    to run your data synchronization and indexing process.
                  </span>

                  <div className="w-100">
                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed p-4 mt-3 mb-2">
                      <KTIcon
                        iconName="paper-clip"
                        className="fs-2tx text-primary me-4"
                      />

                      <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                        <div className="mb-3 mb-md-0 fw-bold">
                          <div
                            className={`fs-6 text-${
                              isFileLoaded === undefined
                                ? "gray-600"
                                : isFileLoaded === false
                                ? "warning"
                                : "primary"
                            } pe-7`}
                          >
                            {isFileLoaded === undefined
                              ? "Upload your service account key JSON file"
                              : isFileLoaded === false
                              ? "Please choose a valid JSON file!"
                              : "The selected file is valid."}
                          </div>
                        </div>

                        <input
                          type="file"
                          accept=".json"
                          style={{ display: "none" }}
                          ref={(input) => (this.fileInput = input)}
                          onChange={this.handleFileSelect}
                        />

                        <a
                          href="#"
                          className="btn btn-primary px-6 align-self-center text-nowrap"
                          onClick={() =>
                            this.fileInput && this.fileInput.click()
                          }
                        >
                          Select
                        </a>
                      </div>
                    </div>

                    {activationMessage ? (
                      <div className="fv-plugins-message-container ms-1">
                        <span
                          className={`text-${hasError ? "danger" : "success"}`}
                        >
                          {activationMessage}
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="d-flex flex-center pt-10">
                  {/* Deactivate feature is not available yet */}
                  {/* {companyStorage ? (
                    <button
                      type="reset"
                      className="btn btn-lg btn-color-danger me-4 fw-bolder px-6 py-3"
                      onClick={() => {
                        this.props.onDeactivate(companyStorage);
                        ReactGA.event({
                          category: "company_storages_page",
                          action: "deactivate_storages",
                          label: "deactivate_storages_button",
                        });
                      }}
                    >
                      Deactivate
                    </button>
                  ) : null} */}

                  <div style={{ flex: 1 }}></div>

                  <div>
                    <button
                      type="reset"
                      className="btn btn-lg btn-color-gray-600 me-4 fw-bolder px-6 py-3"
                      onClick={() => {
                        this.props.onCancel();
                        ReactGA.event({
                          category: "company_storages_page",
                          action: "cancel_set_up_storages",
                          label: "cancel_set_up_storages_button",
                        });
                      }}
                    >
                      Cancel
                    </button>

                    {!companyStorage ? (
                      <button
                        type="button"
                        className="btn btn-lg btn-primary"
                        disabled={
                          credentials === "" || isLoading ? true : false
                        }
                        onClick={() => {
                          if (activationMessage && !hasError) {
                            this.props.onActivate(credentials);
                            ReactGA.event({
                              category: "company_storages_page",
                              action: "test_storages",
                              label: "test_storages_button",
                            });
                          } else {
                            this.handleTestClick(credentials);
                          }
                        }}
                      >
                        {isLoading ? (
                          <span className="spinner-border spinner-border-sm align-middle"></span>
                        ) : activationMessage && !hasError ? (
                          "Activate"
                        ) : (
                          "Test"
                        )}
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-lg btn-primary"
                        disabled={
                          credentials === companyStorage.credentials
                            ? true
                            : false
                        }
                        onClick={() => {
                          if (activationMessage && !hasError) {
                            this.props.onUpdate(companyStorage, credentials);
                            ReactGA.event({
                              category: "company_storages_page",
                              action: "update_storages",
                              label: "update_storages_button",
                            });
                          } else {
                            this.handleTestClick(credentials);
                          }
                        }}
                      >
                        {isLoading ? (
                          <span className="spinner-border spinner-border-sm align-middle"></span>
                        ) : activationMessage && !hasError ? (
                          "Update"
                        ) : (
                          "Test"
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal-backdrop fade show"></div>
      </>
    );
  }
}
