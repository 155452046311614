import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import clsx from "clsx";
import { observer } from "mobx-react";
import ReactGA from "react-ga4";

import { CompanyUserStatus } from "../../../../../helpers/Enums";
import { CompanyUser } from "../../../../models/CompanyUser";
import { toAbsoluteUrl } from "../../../../../helpers";
import i18n from "../../../../../i18n";
import stores from "../../../../stores";
import { CompanyUserListLoading } from "../components/loading/CompanyUserListLoading";

type Props = {
  companyUser?: CompanyUser;
};

const editUserSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  name: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Name is required"),
});

export const CompanyUserEditModalForm: React.FC<Props> = observer(
  ({ companyUser }) => {
    const [view, setView] = useState<"form" | "invites">("form");
    const [filteredInvites, setFilteredInvites] = useState(
      stores.companyUserStore.companyUserInvites.filter(
        (invite) =>
          !stores.companyUserStore.companyUsers.some(
            (user) => user.user.email === invite.email
          )
      )
    );

    const [subscriptionPlan, setSubscriptionPlan] = useState<
      "monthly" | "annual" | null
    >(null);
    const [pricePerUser, setPricePerUser] = useState(0);

    useEffect(() => {
      const subscription = stores.userStore.currentUser.subscription;
      if (subscription) {
        const currentPriceId = subscription.items.data[0].price.id;

        const monthlyPriceId = process.env.REACT_APP_MONTHLY_PRICE_ID;
        const annualPriceId = process.env.REACT_APP_YEARLY_PRICE_ID;

        if (currentPriceId === monthlyPriceId) {
          setSubscriptionPlan("monthly");
          setPricePerUser(49);
        } else if (currentPriceId === annualPriceId) {
          setSubscriptionPlan("annual");
          setPricePerUser(490);
        } else {
          setSubscriptionPlan(null);
        }
      }

      setFilteredInvites(
        stores.companyUserStore.companyUserInvites.filter(
          (invite) =>
            !stores.companyUserStore.companyUsers.some(
              (user) => user.user.email === invite.email
            )
        )
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      stores.companyUserStore.companyUserInvites,
      stores.companyUserStore.companyUsers,
    ]);

    const cancel = () => {
      ReactGA.event({
        category: "company_users",
        action: "discard_invite_new_user",
        label: "discard_invite_new_user_button",
      });
      stores.companyUserStore.selectedCompanyUser = undefined;
      stores.companyUserStore.isCompanyUserEditModalOpened = false;
    };

    const handleDeleteInvite = async (inviteId: number) => {
      try {
        await stores.companyUserStore.deleteCompanyUserInvite(inviteId);
        setFilteredInvites(
          filteredInvites.filter((invite) => invite.id !== inviteId)
        );
      } catch (error) {
        console.error("Failed to delete invite:", error);
      }
    };

    const formik = useFormik({
      initialValues: {
        companyUser: companyUser,
        email: companyUser ? companyUser.user.email : "",
        name: companyUser ? companyUser.user.name : "",
        user_role: companyUser
          ? companyUser.user_role
          : stores.userRoleStore.userRoles[0],
      },
      validationSchema: editUserSchema,
      onSubmit: async (values, { setSubmitting }) => {
        setSubmitting(true);

        try {
          if (values.companyUser) {
            const updatedCompanyUser = values.companyUser;
            updatedCompanyUser.role_id = values.user_role.id;
            updatedCompanyUser.user_role = values.user_role;

            await stores.companyUserStore.updateCompanyUser(updatedCompanyUser);
          } else {
            if (stores.companyStore.selectedUserCompany) {
              await stores.companyUserStore.createCompanyUserInvite(
                values.name ? values.name : "",
                values.email ? values.email : "",
                stores.companyStore.selectedUserCompany.id,
                values.user_role.id,
                CompanyUserStatus.InProgress
              );
            }
          }
        } catch (ex) {
          console.error(ex);
        } finally {
          setSubmitting(false);
          cancel();
        }
      },
    });

    const blankImg = toAbsoluteUrl("/media/svg/avatars/blank.svg");
    const userAvatarImg = toAbsoluteUrl(`${companyUser?.user.picture}`);

    const isMemberRoleSelected = formik.values.user_role?.name === "member";

    return (
      <>
        {view === "form" ? (
          <form
            id="kt_modal_add_user_form"
            className="form"
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <div
              className="d-flex flex-column scroll-y me-n7 pe-7"
              id="kt_modal_add_user_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{default: false, lg: true}"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_add_user_header"
              data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
              data-kt-scroll-offset="300px"
            >
              {companyUser ? (
                <div className="fv-row mb-7">
                  <label className="d-block fw-bold fs-6 mb-5">
                    {i18n.CompanyUserEditModalForm.picture}
                  </label>

                  <div
                    className="image-input image-input-outline"
                    data-kt-image-input="true"
                    style={{ backgroundImage: `url('${blankImg}')` }}
                  >
                    <div
                      className="image-input-wrapper w-125px h-125px"
                      style={{ backgroundImage: `url('${userAvatarImg}')` }}
                    ></div>
                  </div>
                </div>
              ) : null}

              {stores.userStore.isFreeUser ? (
                <div className="text-gray-600 mb-10 fw-semibold fs-6">
                  Free users can only send 2 invites. You have{" "}
                  {2 - stores.companyUserStore.companyUserInvites.length}{" "}
                  invite(s) left.
                  <button
                    type="button"
                    className="text-primary fw-bold btn btn-link p-0"
                    onClick={() => setView("invites")}
                  >
                    See Previously Sent Invites.
                  </button>
                </div>
              ) : null}

              {subscriptionPlan && (
                <div className="alert alert-info">
                  <strong>Note:</strong> Your current plan is{" "}
                  {subscriptionPlan === "monthly" ? "monthly" : "annual"}. Every
                  additional user will be billed at{" "}
                  <strong>
                    {pricePerUser + "$"}{" "}
                    {subscriptionPlan === "monthly" ? "per month" : "per year"}
                  </strong>{" "}
                  once they accept the invite.
                </div>
              )}

              <div className="fv-row mb-7">
                <label className="required fw-bold fs-6 mb-2">
                  {i18n.CompanyUserEditModalForm.fullName}
                </label>

                <input
                  placeholder="Full name"
                  {...formik.getFieldProps("name")}
                  type="text"
                  name="name"
                  value={
                    companyUser ? companyUser.user.name : formik.values.name
                  }
                  className={clsx(
                    "form-control form-control-solid mb-3 mb-lg-0 ms-1",
                    {
                      "is-invalid": formik.touched.name && formik.errors.name,
                    },
                    {
                      "is-valid": formik.touched.name && !formik.errors.name,
                    }
                  )}
                  autoComplete="off"
                  disabled={!companyUser ? false : true}
                />

                {formik.touched.name && formik.errors.name && (
                  <div className="fv-plugins-message-container ms-1">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.name}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className="fv-row mb-7">
                <label className="required fw-bold fs-6 mb-2">
                  {i18n.CompanyUserEditModalForm.email}
                </label>

                <input
                  placeholder="Email"
                  {...formik.getFieldProps("email")}
                  value={
                    companyUser ? companyUser.user.email : formik.values.email
                  }
                  className={clsx(
                    "form-control form-control-solid mb-3 mb-lg-0 ms-1",
                    {
                      "is-invalid": formik.touched.email && formik.errors.email,
                    },
                    {
                      "is-valid": formik.touched.email && !formik.errors.email,
                    }
                  )}
                  type="email"
                  name="email"
                  autoComplete="off"
                  disabled={!companyUser ? false : true}
                />

                {formik.touched.email && formik.errors.email && (
                  <div className="fv-plugins-message-container ms-1">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.email}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className="mb-7">
                <label className="required fw-bold fs-6 mb-5">Role</label>

                {stores.userRoleStore.userRoles.map((userRole, index) => {
                  if (userRole.name === "admin") return null;

                  return (
                    <div className="d-flex fv-row" key={index}>
                      <div className="form-check form-check-custom form-check-solid">
                        <input
                          className="form-check-input me-3"
                          {...formik.getFieldProps("role")}
                          name="role"
                          type="radio"
                          value={userRole.name}
                          id={userRole.name}
                          checked={
                            formik.values.user_role?.name === userRole.name
                          }
                          disabled={formik.isSubmitting}
                          onClick={() =>
                            formik.setFieldValue("user_role", userRole)
                          }
                        />

                        <label
                          className="form-check-label"
                          htmlFor={userRole.name}
                        >
                          <div className="fw-bolder text-gray-800">
                            {userRole.name.charAt(0).toUpperCase() +
                              userRole.name.slice(1)}
                          </div>
                        </label>
                      </div>

                      {stores.userRoleStore.userRoles.length - 1 > index ? (
                        <div className="separator separator-dashed my-5"></div>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="text-center pt-15">
              <button
                type="reset"
                onClick={() => cancel()}
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                disabled={formik.isSubmitting}
              >
                {i18n.CompanyUserEditModalForm.discard}
              </button>

              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={
                  formik.isSubmitting ||
                  !formik.touched ||
                  !isMemberRoleSelected
                }
                onClick={() =>
                  ReactGA.event({
                    category: "company_users",
                    action: "submit_invite_new_user",
                    label: "submit_invite_new_user_button",
                  })
                }
              >
                <span className="indicator-label">
                  {i18n.CompanyUserEditModalForm.submit}
                </span>

                {formik.isSubmitting && (
                  <span className="indicator-progress">
                    {i18n.CompanyUserEditModalForm.pleaseWait}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        ) : (
          <>
            <div className="mb-10">
              <div className="fs-6 fw-bold mb-7">Your Invitations</div>
              <div className="mh-300px scroll-y me-n7 pe-7">
                {filteredInvites.length === 0 ? (
                  <div className="text-center py-4">
                    No pending invites sent.
                  </div>
                ) : (
                  filteredInvites.map((invite, i) => (
                    <div
                      className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed"
                      key={i}
                    >
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-35px symbol-circle">
                          <span className="symbol-label bg-light-info overflow-hidden text-info fw-bold">
                            {invite.name.charAt(0).toUpperCase()}
                          </span>
                        </div>
                        <div className="ms-5">
                          <span className="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2">
                            {invite.name}
                          </span>
                          <div className="fw-bold text-muted">
                            {invite.email}
                          </div>
                        </div>
                      </div>

                      <div className="ms-2">
                        <button
                          type="button"
                          className="btn btn-sm btn-light-danger"
                          onClick={() => handleDeleteInvite(invite.id)}
                        >
                          X
                        </button>
                      </div>
                    </div>
                  ))
                )}
              </div>
              <div className="text-start pt-15">
                <button
                  type="reset"
                  onClick={() => setView("form")}
                  className="btn btn-primary me-3"
                >
                  Back
                </button>
              </div>
            </div>
          </>
        )}

        {formik.isSubmitting && <CompanyUserListLoading />}
      </>
    );
  }
);
