import { action, observable, when } from "mobx";

import {
  createCompanyStorage,
  deleteCompanyStorage,
  getStorages,
  getCompanyStorages,
  updateCompanyStorage,
  checkStorageApiKey,
} from "../../helpers/api";
import { CompanyStorage } from "../models/CompanyStorage";
import { CompanyStorageStatus, UserActionLogStatus } from "../../helpers/Enums";
import { Storage } from "../models/Storage";

import RootStore from "./RootStore";
import stores from ".";

export type CompanyStorageFilter = {
  status: CompanyStorageStatus;
};

export default class CompanyStorageStore {
  @observable storages: Storage[] = [];
  @observable.deep companyStorages: CompanyStorage[] = [];
  @observable.deep nonFilteredCompanyStorages: CompanyStorage[] = [];
  @observable selectedCompanyStorage?: CompanyStorage;
  @observable isSetupStorageModalOpened: boolean = false;
  @observable isLoading: boolean = true;

  constructor(rootStore: RootStore) {
    when(
      () => rootStore.companyStore.selectedUserCompany !== undefined,
      () => {
        this.getCompanyStorages();
      }
    );
  }

  @action getCompanyStorages = async () => {
    this.isLoading = true;

    this.storages = await getStorages();

    this.companyStorages = [];

    if (stores.companyStore.selectedUserCompany !== undefined) {
      this.nonFilteredCompanyStorages = await getCompanyStorages(
        stores.companyStore.selectedUserCompany.id
      );

      // Set S3 as default storage as default S3 in env
      if (
        this.storages.length > 0 &&
        this.nonFilteredCompanyStorages.length === 0
      ) {
        const awsStorage = this.storages.find(
          (storage) => storage.id.toString() === "1"
        );
        const defaultAWSS3StorageCredentials =
          process.env.REACT_APP_S3_CREDENTIALS;

        if (awsStorage && defaultAWSS3StorageCredentials)
          await stores.companyStorageStore.createCompanyStorage(
            awsStorage,
            defaultAWSS3StorageCredentials
          );
      }

      if (this.nonFilteredCompanyStorages.length > 0) {
        this.selectedCompanyStorage = this.nonFilteredCompanyStorages.find(
          (companyStorage) => companyStorage.is_default
        );

        if (
          this.selectedCompanyStorage &&
          this.selectedCompanyStorage.credentials
        )
          await checkStorageApiKey(
            stores.companyStore.selectedUserCompany.id,
            this.selectedCompanyStorage.storage,
            this.selectedCompanyStorage.credentials
          );

        stores.companyAvatarStore.checkGeneralIndex();
      }

      this.filterAndSortCompanyStorages();
    }

    this.isLoading = false;
  };

  @action filterAndSortCompanyStorages = (
    searchTerm?: string,
    filter?: CompanyStorageFilter
  ) => {
    this.isLoading = true;

    this.companyStorages = this.nonFilteredCompanyStorages;

    if (searchTerm)
      this.companyStorages = this.nonFilteredCompanyStorages.filter(
        (companyStorage) => companyStorage.storage.name.includes(searchTerm)
      );

    if (filter) {
      this.companyStorages = this.nonFilteredCompanyStorages.filter(
        (companyStorage) => companyStorage.status === filter.status
      );
    }

    this.isLoading = false;
  };

  @action createCompanyStorage(
    storage: Storage,
    credentials: string = ""
  ): Promise<CompanyStorage> {
    return new Promise(async (resolve, reject) => {
      if (!stores.companyStore.selectedUserCompany) {
        reject();
        return;
      }

      const userActionLog = await stores.userActionLogStore.createUserActionLog(
        `${storage.name} connection`,
        "",
        0,
        0,
        UserActionLogStatus.InProgress
      );

      const createdCompanyStorage = await createCompanyStorage(
        storage.id,
        stores.companyStore.selectedUserCompany.id,
        credentials,
        CompanyStorageStatus.Connected,
        Date.now(),
        this.companyStorages.length === 0 ? true : false
      );

      this.nonFilteredCompanyStorages.push(createdCompanyStorage);

      if (!this.selectedCompanyStorage) {
        this.selectedCompanyStorage = createdCompanyStorage;
        stores.companyAvatarStore.checkGeneralIndex();
      }

      userActionLog.action_status = UserActionLogStatus.Success;
      userActionLog.action_result = `${storage.name} activation successful.`;

      stores.userActionLogStore.updateUserActionLog(userActionLog);

      this.filterAndSortCompanyStorages();

      resolve(createdCompanyStorage);
    });
  }

  @action updateCompanyStorage(
    companyStorage: CompanyStorage
  ): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      await updateCompanyStorage(companyStorage);

      const existingCompanyStorageIndex =
        this.nonFilteredCompanyStorages.findIndex(
          (item) => item.id === companyStorage.id
        );

      if (existingCompanyStorageIndex !== -1) {
        this.nonFilteredCompanyStorages[existingCompanyStorageIndex] =
          companyStorage;
      }

      this.filterAndSortCompanyStorages();

      resolve(true);
    });
  }

  @action deleteSelectedCompanyStorage(
    companyStorage: CompanyStorage
  ): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      await deleteCompanyStorage(companyStorage.id);

      this.nonFilteredCompanyStorages = this.nonFilteredCompanyStorages.filter(
        (item) => item.id !== companyStorage.id
      );

      this.filterAndSortCompanyStorages();

      resolve(true);
    });
  }
}
