import React from "react";
import { Table } from "react-bootstrap";

const TokenPriceTable = () => {
  const tokenData = [
    {
      model: "OpenAI - GPT-4",
      inputPrice: "$0.005",
      outputPrice: "$0.015",
      ourPrice: "$0.2666",
    },
    {
      model: "Mistral AI - Open-Mistral-7b",
      inputPrice: "$0.25",
      outputPrice: "$0.25",
      ourPrice: "$0.5251",
    },
    {
      model: "Cohere AI - Command-Nightly",
      inputPrice: "$0.50",
      outputPrice: "$1.50",
      ourPrice: "$1.9001",
    },
    {
      model: "Google Chat AI - Chat-Bison-32k",
      inputPrice: "$1.20",
      outputPrice: "$2.00",
      ourPrice: "$2.4501",
    },
    {
      model: "Google Code Chat AI - CodeChat-Bison-32k",
      inputPrice: "$1.20",
      outputPrice: "$2.00",
      ourPrice: "$2.4501",
    },
    {
      model: "Anthropic AI - Claude-3-Haiku-20240307",
      inputPrice: "$0.25",
      outputPrice: "$1.25",
      ourPrice: "$1.6251",
    },
    {
      model: "Avatars",
      inputPrice: "-",
      outputPrice: "-",
      ourPrice: "$3.0001",
    },
    {
      model: "The Chief",
      inputPrice: "-",
      outputPrice: "-",
      ourPrice: "$4.1001",
    },
  ];

  return (
    <div className="container">
      <h2 className="mb-5">Premium Plan Token Pricing</h2>
      <Table bordered hover responsive>
        <thead className="bg-light">
          <tr>
            <th>Model</th>
            <th>Input Price (per 25K tokens)</th>
            <th>Output Price (per 25K tokens)</th>
            <th>Our Price (per 25K tokens)</th>
          </tr>
        </thead>
        <tbody>
          {tokenData.map((token, index) => (
            <tr key={index}>
              <td>{token.model}</td>
              <td>{token.inputPrice}</td>
              <td>{token.outputPrice}</td>
              <td>{token.ourPrice}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default TokenPriceTable;
