import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { WizardContent } from "./WizardComponents";
import TokenPriceTable from "./TokenPriceTable";
import { KTIcon } from "../../../../helpers";
import stores from "../../../stores";
import { SelectPlanLoading } from "./SelectPlanLoading";

const stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "";

interface SelectPlanProps {
  onNext: () => void;
  onBack: () => void;
  formData: any;
  setFormData: (data: any) => void;
}

const SelectPlan: React.FC<SelectPlanProps> = ({
  onNext,
  onBack,
  formData,
  setFormData,
}) => {
  const [selectedPlan, setSelectedPlan] = useState(
    formData.selectedPlan || "Premium"
  );
  const [billingType, setBillingType] = useState<"monthly" | "annual">(
    "monthly"
  );
  const [showModal, setShowModal] = useState(false);
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const isTestMode = stripeKey.includes("test");

  useEffect(() => {
    const checkSubscription = async () => {
      setIsLoading(true);
      const hasSubscription = await stores.userStore.checkUserSubscription();
      setHasActiveSubscription(hasSubscription);
      setIsLoading(false);
    };

    checkSubscription();
  }, []);

  const plans = [
    {
      title: "Free",
      subTitle: "Essential Features for Small Teams",
      description:
        "Access up to 3 applications, manage 3 employees, and connect 1 admin. Includes storage and LLM connection. Ideal for small teams and startups needing essential features to get started.",
      priceMonth: "0$",
      priceAnnual: "0$",
      priceIdMonth: "",
      priceIdAnnual: "",
      paymentLinks: {
        month: "#",
        annual: "#",
      },
      default: true,
      custom: false,
      tokenInfo: "25K token limit",
      features: [
        { title: "Access to 3 applications", supported: true },
        { title: "1 LLM connection", supported: true },
        { title: "1 storage connection", supported: true },
        { title: "1 Admin", supported: true },
        { title: "VoiceAsk", supported: false },
        { title: "Avatars", supported: false },
        { title: "Auto Sync", supported: false },
        { title: "Custom LLM tuning", supported: false },
        { title: "The Chief", supported: false },
        { title: "Use API", supported: false },
      ],
    },
    {
      title: "Premium",
      subTitle: "Advanced Features for Growing Teams",
      description:
        "Enjoy unlimited applications, employees, LLMs, and storage connections. Includes premium tools like Auto Sync and VoiceAsk. Perfect for teams requiring flexibility and more advanced functionality.",
      priceMonth: "49$",
      priceAnnual: "490$",
      isPopular: true,
      priceIdMonth: process.env.REACT_APP_MONTHLY_PRICE_ID,
      priceIdAnnual: process.env.REACT_APP_YEARLY_PRICE_ID,
      paymentLinks: {
        month: "#",
        annual: "#",
      },
      default: false,
      custom: false,
      tokenInfo: "Unlimited token",
      features: [
        { title: "Unlimited access to applications", supported: true },
        { title: "Unlimited LLM connections", supported: true },
        { title: "Unlimited storage connection", supported: true },
        { title: "1 Admin", supported: true },
        { title: "VoiceAsk", supported: true },
        { title: "1 Avatar", supported: true },

        { title: "Auto Sync", supported: true },
        { title: "Custom LLM tuning", supported: false },
        { title: "The Chief", supported: false },
        { title: "Use API", supported: false },
      ],
    },
    {
      title: "Enterprise",
      subTitle: "Comprehensive Solutions for Large Enterprises",
      description:
        "Full access to advanced features like unlimited admins, storage, and employees. Get specialized services like custom LLMs. Perfect for large organizations with specific needs.",
      default: false,
      custom: true,
      priceId: isTestMode
        ? "price_1PqZumBEosKxgEvXihK0LN6z"
        : "price_1PqweqwosKxgEmvXihK0LN6z",
      tokenInfo: "Unlimited token",
      features: [
        { title: "Unlimited access to applications", supported: true },
        { title: "Unlimited LLM connections", supported: true },
        { title: "Unlimited storage connections", supported: true },
        { title: "Unlimited admin additions", supported: true },
        { title: "VoiceAsk", supported: true },
        { title: "Avatars", supported: true },
        { title: "Auto Sync", supported: true },

        { title: "Custom LLM tuning", supported: true },
        { title: "The Chief", supported: true },
        { title: "Use API", supported: true },
      ],
    },
  ];

  const handlePlanSelect = (planTitle: string) => {
    let priceId = "";

    const selectedPlanObj = plans.find((plan) => plan.title === planTitle);

    if (selectedPlanObj) {
      if (planTitle === "Enterprise") {
        priceId = selectedPlanObj.priceId || "";
      } else if (planTitle === "Free") {
        priceId = "";
      } else {
        priceId =
          billingType === "monthly"
            ? selectedPlanObj.priceIdMonth || ""
            : selectedPlanObj.priceIdAnnual || "";
      }
    }

    setSelectedPlan(planTitle);
    setFormData({
      ...formData,
      selectedPlan: planTitle,
      priceId: priceId,
    });
  };

  const handleBillingTypeChange = (type: "monthly" | "annual") => {
    setBillingType(type);

    setFormData({
      ...formData,
      billingType: type,
    });

    if (selectedPlan === "Premium") {
      const selectedPlanObj = plans.find((plan) => plan.title === "Premium");
      if (selectedPlanObj) {
        const priceId =
          type === "monthly"
            ? selectedPlanObj.priceIdMonth
            : selectedPlanObj.priceIdAnnual;
        setFormData((prevFormData: any) => ({
          ...prevFormData,
          priceId: priceId || "",
          billingType: type,
        }));
      }
    } else {
      setFormData({
        ...formData,
        billingType: type,
      });
    }
  };

  if (isLoading) {
    return <SelectPlanLoading />;
  }

  if (hasActiveSubscription) {
    return (
      <div className="text-center mt-20 mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20">
        <h3 className="mb-4">You already have an active subscription!</h3>
        <p className="text-muted">
          Your current subscription provides all the features you need. If you
          want to make changes to your subscription, please upgrade your plan in
          the dashboard.
        </p>

        <div
          className="fixed-bottom bg-white pb-20 pt-10"
          style={{ height: "80px" }}
        >
          <div className="container d-flex justify-content-between w-50">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onBack}
            >
              Back
            </button>
            <button type="button" className="btn btn-primary" onClick={onNext}>
              Next
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <form className="form mx-auto">
      <WizardContent>
        <div style={{ paddingBottom: "120px" }}>
          <div className="container py-15 ">
            <div className="text-center mb-10">
              <h1 className="mb-3">Select Your Plan</h1>
              <div className="text-muted fw-bold fs-5">
                Choose the best plan for your company.
              </div>
            </div>

            {/* Center Billing Type Toggle */}
            <div className="d-flex justify-content-center mb-10">
              <div className="nav nav-tabs border-0">
                <button
                  type="button"
                  className={`nav-link btn btn-outline-secondary ${
                    billingType === "monthly" ? "active" : ""
                  }`}
                  onClick={() => handleBillingTypeChange("monthly")}
                  disabled={selectedPlan === "Enterprise"}
                >
                  Monthly Billing
                </button>
                <button
                  type="button"
                  className={`nav-link btn btn-outline-secondary ${
                    billingType === "annual" ? "active" : ""
                  }`}
                  onClick={() => handleBillingTypeChange("annual")}
                  disabled={selectedPlan === "Enterprise"}
                >
                  Annual Billing
                </button>
              </div>
            </div>

            <div className="row g-10 align-items-stretch">
              {plans.map((plan, index) => (
                <div className="col-lg-4" key={index}>
                  <div
                    className={`card card-bordered card-hover h-100 ${
                      selectedPlan === plan.title ? "bg-light-primary" : ""
                    }`}
                    onClick={() => handlePlanSelect(plan.title)}
                    style={{ cursor: "pointer", position: "relative" }}
                  >
                    <div className="card-body text-center p-10">
                      <h3 className="card-title mb-2">{plan.title}</h3>

                      {/* Show "Most Popular" badge for Premium plan */}
                      {plan.isPopular && (
                        <span
                          style={{
                            position: "absolute",
                            top: "-10px",
                            right: "-10px",
                            backgroundColor: "#ffc107",
                            color: "#000",
                            padding: "5px 10px",
                            borderRadius: "5px",
                            fontSize: "12px",
                            fontWeight: "bold",
                          }}
                        >
                          Most Popular
                        </span>
                      )}

                      <p className="card-text text-muted mb-4">
                        {plan.subTitle}
                      </p>

                      {/* Show Price or Token-based info */}
                      <h4 className="fw-bold text-primary mb-4 fs-2">
                        {plan.title === "Enterprise" ? (
                          <div className="text-primary fw-bold mb-0">
                            Contact Us
                          </div>
                        ) : (
                          <>
                            {billingType === "monthly"
                              ? plan.priceMonth
                              : plan.priceAnnual}
                            /{billingType}
                            {plan.title === "Premium" && (
                              <span className="fs-6 text-muted"> per user</span>
                            )}
                          </>
                        )}
                      </h4>

                      <p className="text-muted">{plan.description}</p>

                      {/* Features List */}
                      <div className="w-100 mb-10">
                        {plan.tokenInfo && (
                          <div className="alert">
                            <strong>{plan.tokenInfo}</strong>
                          </div>
                        )}
                        {plan.features.map((feature, idx) => (
                          <div
                            className="d-flex align-items-center mb-7"
                            key={idx}
                          >
                            <span
                              className={`fw-bold fs-5 ${
                                feature.supported
                                  ? "text-gray-700"
                                  : "text-gray-400"
                              } flex-grow-1`}
                            >
                              {feature.title}
                            </span>
                            <KTIcon
                              iconName={
                                feature.supported
                                  ? "check-circle"
                                  : "cross-circle"
                              }
                              className={`fs-1 ${
                                feature.supported ? "text-success" : ""
                              }`}
                            />
                          </div>
                        ))}
                      </div>

                      <button
                        type="button"
                        className={`btn btn-${
                          selectedPlan === plan.title
                            ? "primary"
                            : "outline-primary"
                        } mt-3`}
                      >
                        {selectedPlan === plan.title ? "Selected" : "Select"}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </WizardContent>

      {/* Footer section only for SelectPlan */}
      <div
        className="fixed-bottom bg-white pb-20 pt-10"
        style={{ height: "80px" }}
      >
        <div className="container d-flex justify-content-between w-50">
          <button type="button" className="btn btn-secondary" onClick={onBack}>
            Back
          </button>
          <button
            type="button"
            className="btn btn-primary"
            disabled={!selectedPlan}
            onClick={onNext}
          >
            Next
          </button>
        </div>
      </div>

      {/* Modal for Enterprise Token Pricing */}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Enterprise Token Pricing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TokenPriceTable />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </form>
  );
};

export default SelectPlan;
