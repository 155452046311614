import React from "react";
import { useNavigate } from "react-router-dom";
import { KTSVG } from "../../../../helpers"; // İkonları getirmek için KTSVG kullanabilirsiniz.

const CancelPage: React.FC = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/chat"); // Kullanıcıyı yeniden ödeme sayfasına yönlendirin
  };

  return (
    <div className="d-flex flex-column flex-root">
      <div className="d-flex flex-column flex-center flex-column-fluid">
        <div className="w-lg-600px p-10 p-lg-15 mx-auto">
          <div className="text-center mb-10">
            <h1 className="text-dark mb-3">Payment Cancelled!</h1>
            <div className="text-gray-400 fw-bold fs-5 mb-10">
              Your payment was cancelled or failed. Please try again.
            </div>
            <KTSVG
              path="/media/icons/duotune/general/gen040.svg" // Kırmızı hata ikonu
              className="svg-icon svg-icon-1 svg-icon-danger"
            />
          </div>
          <div className="text-center">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleRedirect}
            >
              Go to Dashboard
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelPage;
